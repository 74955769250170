import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import settings from '../../../common/settings';

const customButtons = [{ type: 'spacer' }, { type: 'undo' }, { type: 'redo' }];

const getAnnotationData = async(attachmentId) => {
  const url = `${settings.annotationUrl}${attachmentId}`;
  const response = await fetch(url, { method: 'GET' });
  if (response.ok) {
    const instantJSON = await response.json();
    return instantJSON?.data || null;
  }
  return null;
};

const AttachmentPdfViewer = (props) => {
  const { attachment } = props;
  const { url } = attachment;
  const containerRef = useRef(null);

  let instance;

  useEffect(() => {
    const container = containerRef.current;
    const documentUrl = `${settings.metaViewUrl}${attachment.uid}`;
    let PSPDFKit;
    (async function() {
      const instantJSON = await getAnnotationData(attachment.uid);
      PSPDFKit = await import('pspdfkit');

      const toolbarItems = PSPDFKit.defaultToolbarItems.concat(customButtons);

      instance = await PSPDFKit.load({
        // Container where PSPDFKit should be mounted.
        container,
        // The document to open.
        document: documentUrl,
        licenseKey: settings.getPSPDFKitToken(),
        baseUrl: settings.getBaseUrl(),
        enableHistory: true,
        instantJSON: instantJSON,
        toolbarItems: toolbarItems,
      });
      instance.addEventListener('annotations.didSave', saveAnnotationHandler);
    })();

    return () => {
      PSPDFKit && PSPDFKit.unload(container);
      instance &&
        instance.removeEventListener(
          'annotations.didSave',
          saveAnnotationHandler
        );
    };
  }, [url]);

  const saveAnnotationHandler = async() => {
    await instance.save();
    const instantJSON = await instance.exportInstantJSON();
    const url = `${settings.annotationUrl}${attachment.uid}`;
    await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(instantJSON),
    });
  };

  return <div ref={containerRef} style={{ width: '100%', height: '100%' }} />;
};

AttachmentPdfViewer.displayName = 'AttachmentPdfViewer';

AttachmentPdfViewer.propTypes = {
  attachment: PropTypes.object.isRequired,
};

export default AttachmentPdfViewer;
