import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import classnames from 'classnames';
import { Button } from '@trussworks/react-uswds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import settings from '../../common/settings';

const PaginationByMonth = (props) => {
  const {
    previousOffsetMonths,
    nextOffsetMonths,
    numberMonthsVisible,
    onChange,
    selected,
    dateFormat,
  } = props;

  const [selectedMonth, setSelectedMonth] = useState(
    dayjs(selected, dateFormat).format(dateFormat)
  );

  const getSelectedMoment = () => dayjs(selectedMonth, dateFormat);
  const getNextMonth = () => getSelectedMoment().add(1, 'month');
  const getPreviousMonth = () => getSelectedMoment().subtract(1, 'month');

  const isFirstMonth = () => {
    // unlimited
    if (previousOffsetMonths <= 0) {
      return false;
    }

    if (
      dayjs()
        .subtract(previousOffsetMonths, 'month')
        .isSame(getSelectedMoment(), 'month')
    ) {
      return true;
    }

    return false;
  };

  const isLastMonth = () => {
    // unlimited
    if (nextOffsetMonths <= 0) {
      return false;
    }

    if (
      dayjs().add(nextOffsetMonths, 'month').isSame(getSelectedMoment(), 'month')
    ) {
      return true;
    }

    return false;
  };

  const months = [
    isFirstMonth() ? null : getPreviousMonth(),
    getSelectedMoment(),
    isLastMonth() ? null : getNextMonth(),
  ];

  const handleNextClick = () => {
    const nextMonth = getNextMonth().format(dateFormat);
    onSelectHandler(nextMonth);
  };

  const handlePrevClick = () => {
    const prevMonth = getPreviousMonth().format(dateFormat);
    onSelectHandler(prevMonth);
  };

  const prevButton = (
    <Button
      type="button"
      className="item-nav-button"
      unstyled
      disabled={isFirstMonth()}
      onClick={handlePrevClick}
    >
      <FontAwesomeIcon
        icon={'angle-left'}
        size={'lg'}
        style={{ marginRight: 4 }}
      />
    </Button>
  );

  const nextButton = (
    <Button
      type="button"
      className="item-nav-button"
      unstyled
      disabled={isLastMonth()}
      onClick={handleNextClick}
    >
      <FontAwesomeIcon
        icon={'angle-right'}
        size={'lg'}
        style={{ marginLeft: 4 }}
      />
    </Button>
  );

  const onSelectHandler = (value) => {
    setSelectedMonth(value);
    onChange(value);
  };

  return (
    <div className="display-flex pagination-container">
      <div className="">{prevButton}</div>
      {months.map((m) => {
        if (!m) {
          return null;
        }
        const month = m.format(dateFormat);
        return (
          <Button
            className={classnames(
              'pagination-link',
              month === selectedMonth ? 'active' : ''
            )}
            type="button"
            unstyled
            key={month}
            onClick={() => onSelectHandler(month)}
          >
            {month}
          </Button>
        );
      })}
      <div className="">{nextButton}</div>
    </div>
  );
};

PaginationByMonth.displayName = 'PaginationByMonth';

PaginationByMonth.propTypes = {
  /** Max historical months. 0 is unlimited, default is 3 */
  previousOffsetMonths: PropTypes.number,
  /** Max future months. 0 is unlimited, default is 3 */
  nextOffsetMonths: PropTypes.number,
  /** Number of months are visible. default is 3 */
  numberMonthsVisible: PropTypes.number,
  /** Callback function with selected month value. */
  onChange: PropTypes.func.isRequired,
  /** Initial seleted month, default is the current month */
  selected: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  /** Page item title format */
  dateFormat: PropTypes.string,
};

PaginationByMonth.defaultProps = {
  previousOffsetMonths: 0,
  nextOffsetMonths: 0,
  numberMonthsVisible: 3,
  dateFormat: settings.paginationDateFormat,
};

export default PaginationByMonth;
