import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaginationByMonth from '../Common/PaginationByMonth';
import { useGetBookmarksQuery } from '../../services/bookmarkApi'
import { Accordion } from "@trussworks/react-uswds";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BookmarkTable from "./BookmarkTable";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { getDateInOffsetTime } from '../../helpers/dayjsHelpers';
import settings from '../../common/settings';
import classNames from 'classnames';
import { selectExpanded } from '../Navigation/navigationSlice';
import { selectSelectedMonth, setSelectedMonth } from './bookmarkSlice';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export default function BookmarkIndex() {
  let history = useHistory();
  const dispatch = useDispatch();
  const selectedMonth = useSelector(selectSelectedMonth);
  const startTime = dayjs(selectedMonth, settings.paginationDateFormat)
    .startOf('month')
    .format(settings.memaStartTimeUrlParamFormat);
  const endTime = dayjs(selectedMonth, settings.paginationDateFormat)
    .endOf('month')
    .format(settings.memaEndTimeUrlParamFormat);
  const { data, isLoading, isFetching, isError, isSuccess } = useGetBookmarksQuery({ startTime, endTime });
  const expanded = useSelector(selectExpanded);
  const pageChangeHandler = (month) => {
    dispatch(setSelectedMonth(month));
  };

  const accordionEvents = () => {
    return (
      data.bookmarkedItemsByEvent.map((event) => {

        // The event items from the GetBookmarksQuery are the items that have bookmarks in agenda order. Use this to build the list of bookmarks,
        const bookmarks = event.items.map((item) => {
          return { id: item.id, uid: item.uid, title: item.name }
        })

        // click handler for each bookmark row in the table. The URL needs the event uid, so we are creating it here instead of inside table.
        // Save the current page name and url in the history to enable the breadcrumb link on the agenda item page to return here.
        const handleClickRow = (itemUid) => {
          history.push({
            pathname: `agendas/${ event.uid }/${ itemUid }`,
            state: {
              month: selectedMonth,
              prevPath: '/bookmarks',
            }
          });
        };
        const dateInOffsetTime = getDateInOffsetTime(event.starttime);
        return {
          // return an accordion item for our data array passed to Accordion
          id: event.id,
          title: `${ event.name }, ${ dateInOffsetTime.format(settings.monthAndTimeDisplayFormat) }`,
          content: <BookmarkTable items={ bookmarks } handleClickRow={ handleClickRow }/>,
          expanded: true,
          key: event.id
        }
      })
    )
  }

  const renderContent = () => {
    if (isError) {
      return (
        <p className='accordion-none'>
          We encountered a problem while loading the bookmarked items.
          <br/>
          Please try again later.
        </p>
      );
    }
    if (isLoading || isFetching) {
      return (
        <div
          className='display-flex flex-column flex-align-center flex-justify-center text-blue'
          style={ { minHeight: 200 } }
        >
          <FontAwesomeIcon
            icon={ 'spinner' }
            size={ '2x' }
            className='fa-spin'
            style={ { marginBottom: 8 } }
          />
          Loading...
        </div>
      );
    }

    if (data.bookmarkedItemsByEvent?.length > 0)
      return <Accordion items={ accordionEvents() } bordered={ true } multiselectable={ true }/>
    else
      return <p>
        Bookmarks were not found for this month.
      </p>

  };

  useEffect(() => {
    document.title = 'Legislate';
    return () => {
      // reset seletedMonth while leaving the video index page
      dispatch(setSelectedMonth(undefined));
    };
  }, [dispatch]);

  return (
    <div id={ 'bookmarks-container' }>
      <div id={ 'accordion-container' }>
        { renderContent() }
      </div>
      { isSuccess && selectedMonth && (
        <div
          className={ classNames('pagination', 'videos-pagination', {
            expanded: !expanded,
          }) }
        >
          <PaginationByMonth
            selected={ selectedMonth }
            onChange={ pageChangeHandler }
            previousOffsetMonths={ 0 }
            nextOffsetMonths={ 0 }
          />
        </div>
      ) }
    </div>
  );
}

BookmarkIndex.displayName = 'Bookmark Index'



