import { createSlice } from '@reduxjs/toolkit';
import { videoApi } from '../../services/videoApi';
import dayjs from 'dayjs';
import settings from '../../common/settings';

const initialState = {
  list: [],
  selectedMonth: dayjs().format(settings.paginationDateFormat),
};

const video = createSlice({
  name: 'video',
  initialState,
  reducers: {
    setSelectedMonth: (state, { payload }) => {
      state.selectedMonth = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      videoApi.endpoints.getVideos.matchFulfilled,
      (state, { payload }) => {
        state.list = payload;
      }
    );
  },
});

export default video.reducer;

export const { setSelectedMonth } = video.actions;

export const selectVideos = (state) => state.videos.list;

export const selectSelectedMonth = (state) =>
  state.videos.selectedMonth || dayjs().format(settings.paginationDateFormat);