import { createSlice } from '@reduxjs/toolkit';
import { bookmarkApi } from '../../services/bookmarkApi';
import dayjs from 'dayjs';
import settings from '../../common/settings';

const initialState = {
  list: [],
  selectedMonth: dayjs().format(settings.paginationDateFormat),
};

const bookmark = createSlice({
  name: 'bookmark',
  initialState,
  reducers: {
    setSelectedMonth: (state, { payload }) => {
      state.selectedMonth = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      bookmarkApi.endpoints.getBookmarks.matchFulfilled,
      (state, { payload }) => {
        state.list = payload;
      }
    );
  },
});

export default bookmark.reducer;
export const { setSelectedMonth } = bookmark.actions;

export const selectBookmarks = (state) => state.bookmarks.list;

export const selectSelectedMonth = (state) =>
  state.bookmarks.selectedMonth || dayjs().format(settings.paginationDateFormat);