import React, { useState, useEffect, useRef } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form,
  Label,
  TextInput,
  Button,
  Fieldset,
  Alert,
} from '@trussworks/react-uswds';
import {
  GranicusLogoMixed,
  LegislateLogoSmallWhite,
} from '../../../../assets/images/legislate/imageIndex';
import { useLoginMutation } from '../../services/authApi';
import { setCredentials } from './authSlice';

export default function LoginForm() {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [login, { isLoading }] = useLoginMutation();

  const [errorState, setErrorState] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const errorRef = useRef(null);

  useEffect(() => {
    if (errorState) {
      errorRef?.current?.focus();
    }
  }, [errorState]);

  if (isLoggedIn) return <Redirect push to='/' />;


  const captureErrors = (resp) => {
    const { error } = resp
    if (!error) { return resp; }
    else if (error.data) {
      setErrorState('authenticationError');
      setErrorMessage(error.data.error);
    }
    else {
      setErrorState('serverError');
      setErrorMessage('Exception occurred while communicating with server');
    }

    return resp;
  };


  const captureWarnings = (resp) => {
    const { data } = resp
    if (!data?.warning || !data?.warning.length) return resp;

    const { warning: warnings } = data;
    const warning = warnings[0]
    const warning_parts = warning.split(' ') // ['Password', 'validation', 'warning', '15']
    const days = warning_parts[warning_parts.length - 1]
    const warningMessage = [`Your password will expire in ${days} days. \nPlease go here and sign in to update it: /myaccount.php`]

    setErrorState(resp.state);
    setErrorMessage(warningMessage);

    return resp;
  };

  const redirectToHome = (resp) => {
    const gat = resp?.data?.gat;
    if (!gat) return resp;

    dispatch(setCredentials(gat));
    history.push('/');
    return resp;
  };

  const submit = (e) => {
    e.preventDefault();
    setErrorState(null);
    login({ username, password })
      .then(captureErrors)
      .then(captureWarnings)
      .then(redirectToHome)
      .catch((error) => {
        setPassword('');
        setErrorState('serverError');
        setErrorMessage('Exception occurred while communicating with server');
      });
  };

  return (
    <div className='login'>
      <div className='legislate-scroll-bar'>
        <div className='login-flexColumn'>
          <div className='login-LegislateLogo'>
            <img src={LegislateLogoSmallWhite} alt='' />
            <h1 aria-label='Legislate by Granicus'>Legislate</h1>
          </div>
          {/* { errorState === 'serverError' && <div className="error">{ errorMessage }</div> } */}

          <Form onSubmit={submit} className='login-form' data-testid='app'>
            <Fieldset
              legend='Sign In'
              legendStyle='large'
              className='login-fieldset'
            >
              {errorState === 'authenticationError' && (
                <div ref={errorRef} tabIndex={0}>
                  <Alert className='login-alert' type='error'>
                    Oops! The Password or Username is incorrect. Please check to
                    see if your username is correct. Also remember that
                    passwords are case-sensitive
                  </Alert>
                </div>
              )}
              <Label htmlFor='username'>Username</Label>
              <TextInput
                id='username'
                name='username'
                type='text'
                autoCapitalize='off'
                autoCorrect='off'
                onChange={(e) => setUsername(e.target.value)}
              />
              <Label htmlFor='password-sign-in'>Password</Label>
              <TextInput
                id='password-sign-in'
                name='password-sign-in'
                type='password'
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button type='submit' role='button' id='sign-in-form-button' disabled={isLoading}>
                {isLoading ? 'Signing in...' : 'Sign in'}
              </Button>
              <p>
                <a href='forgot_password' title='Forgot password'>
                  Forgot password?
                </a>
              </p>
            </Fieldset>
          </Form>
          <img
            className='login-granicusLogo'
            src={GranicusLogoMixed}
            alt='Granicus Logo'
            aria-hidden='true'
          />
        </div>
      </div>
    </div>
  );
}

LoginForm.displayName = 'Login Form';
