import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@trussworks/react-uswds';
import {
  downloadByMetaUid,
  getFileExtension,
} from '../../../helpers/fileHelper';

const AttachmentDownload = (props) => {
  const { attachment } = props;

  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState(false);

  const fileExt = getFileExtension(attachment.url);

  const downloadAttachment = () => {
    setDownloading(true);
    setError(false);

    downloadByMetaUid(attachment.uid, attachment.name, fileExt)
      .catch(() => {
        setError(true);
      })
      .finally(() => setDownloading(false));
  };

  return (
    <div className='attachment-download-container'>
      <div>
        <div className='display-flex attachment-title'>
          <p className='truncate-text flex-1'>
            To view the attachment&nbsp;
            <strong>{attachment.name}</strong>&nbsp;
            {`${fileExt ? '(.' + fileExt + ')' : '(unknown)'}`}
          </p>
        </div>
        <p>Click to download</p>
        <Button
          role='button'
          onClick={downloadAttachment}
          className='margin-right-0 margin-top-3'
          disabled={downloading}
        >
          {downloading ? 'Downloading' : 'Download'}
        </Button>
        {error && (
          <p className='text-red'>Download error, please try again later!</p>
        )}
      </div>
    </div>
  );
};

AttachmentDownload.displayName = 'AttachmentDownload';

AttachmentDownload.propTypes = {
  // eslint-disable-next-line react/require-default-props
  attachment: PropTypes.object,
};

export default AttachmentDownload;
