import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const agendaApi = createApi({
  reducerPath: 'agendaApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getAgendas: builder.query({
      query: ({ startTime, endTime }) =>
        `/api/legislate/agendas?startTime=${startTime}&endTime=${endTime}`,
    }),
    getActiveAgenda: builder.query({
      query: (uid) => `legislate/api/agendas/${uid}`,
    }),
  }),
});

export const { useGetAgendasQuery, useGetActiveAgendaQuery } = agendaApi;
