import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from '@trussworks/react-uswds';
import { GranicusLogoBlack } from '../../../../assets/images/legislate/imageIndex';
import { useGetCustomerQuery } from '../../services/customerApi';
import { useGetSettingQuery } from '../../services/settingApi';
import { setSettingValue } from '../../common/settingSlice';

export default function LegislateHeader() {
  const { data: customerData, isSuccess } = useGetCustomerQuery();
  const customerName = customerData?.data?.name;
  const pageTitle = useSelector(state => state.currentPage.title);
  const dispatch = useDispatch();

  const { data: settingData, isSuccess: isSettingSuccess, isError: isSettingError } = useGetSettingQuery("cloudvoting_enable");
  useEffect(() => {
    if (isSettingSuccess) {
      dispatch(setSettingValue(settingData));
    }
  }, [isSettingSuccess])

  return (
    <>
      <Header
        id='legislate-desktop-header'
        data-testid='legislate-desktop-header'
        basic={true}
      >
        <div
          className='legislate-header-section'
          id='granicus-logo-header-section'
        >
          <img
            src={GranicusLogoBlack}
            alt='Granicus Logo'
            id='granicus-header-logo'
          />
        </div>
        <div className='legislate-header-section' id='location-header-section'>
          <h1 id='location-indicator' aria-label='current view'>
            {pageTitle}
          </h1>
        </div>
        <div
          className='legislate-header-section'
          id='jurisdiction-header-section'
        >
          {isSuccess && (
            <h4 id='jurisdiction-indicator' aria-label='customer name'>
              {customerName}
            </h4>
          )}
        </div>
      </Header>
    </>
  );
}

LegislateHeader.displayName = 'LegislateHeader';
