import { createSlice } from '@reduxjs/toolkit';
import { agendaApi } from '../services/agendaApi';
import { videoApi } from '../services/videoApi';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const initialState = {
  lastSyncedAgendas: undefined,
  lastSyncedNotes: undefined,
  lastSyncedVideos: undefined,
};

const syncStatus = createSlice({
  name: 'syncStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(agendaApi.endpoints.getAgendas.matchFulfilled, (state) => {
        state.lastSyncedAgendas = dayjs().format('MM/DD/YY H:mm A z');
      })
      .addMatcher(videoApi.endpoints.getVideos.matchFulfilled, (state) => {
        state.lastSyncedVideos = dayjs().format('MM/DD/YY H:mm A z');
      });
  },
});

export default syncStatus.reducer;

export const selectLastSyncedAgendas = (state) =>
  state.syncStatus.lastSyncedAgendas;
export const selectLastSyncedVideos = (state) =>
  state.syncStatus.lastSyncedVideos;
