// Button text.
export const START_EVENT_BUTTON_TEXT = 'START EVENT';
export const STOP_EVENT_BUTTON_TEXT = 'STOP EVENT';
export const PAUSE_EVENT_BUTTON_TEXT = 'PAUSE EVENT';
export const RESUME_EVENT_BUTTON_TEXT = 'RESUME EVENT';
export const EXTEND_EVENT_BUTTON_TEXT = 'EXTEND EVENT';
export const GET_EVENT_STATUS_BUTTON_TEXT = 'GET EVENT STATUS';

// Event state enum.
export const EventStateEnum = {
  Live: 'Live',
  Running: 'Running',
  Stopped: 'Stopped',
  Paused: 'Paused',
};

// Transitional states.
export const EventTransitionEnum = {
  None: 'None',
  Starting: 'Starting',
  Ending: 'Ending',
  Pausing: 'Pausing',
  Resuming: 'Resuming',
  Extending: 'Extending'
};

// aligned with ClipActionLog table in Mema.
export const ClipActionLogStateEnum = {
  Start: 'start',
  Stop: 'stop',
  Pause: 'pause',
  Resume: 'resume',
  Extend: 'extend'
};

// Agenda state enum.
export const AgendaStateEnum = {
  loading: 'loading',
  finished: 'finished',
  failed: 'failed',
  denied: 'denied',
  empty: 'empty',
  continueEmpty: 'continue with empty agenda'
};

// Event operation enum.
export const EventOperationEnum = {
  StartingEvent: 'Starting Event',
  StoppingEvent: 'Stopping Event',
  PausingEvent: 'Pausing Event',
  ResumingEvent: 'Resuming Event',
  ExtendingEvent: 'Extending Event'
};