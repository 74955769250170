import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  memberDetails: {
    memberId: '',
    memberJoinStatus: false,
    isAttendeePresent: false,
    isChair: false,
    isVoter:false
  },
  motionDetails: {
    isMotionEnabled: false,
    allowMotions: false,
    motionId: '',
    motionItemId: '',
    position: '',
    motionTo: '',
    motionMover: '',
    motionSeconder: '',
    previousMotion: undefined, // { motionTo: 'To', mover: 'Mover here', seconder: 'seconder here', }
  },
  agendaItemsDictionary: {},
  // CV TEMP TO BE CHANGED
  isVotingStarted: false,
  meetingGuid: '',
  votecastAgendaUid: '',
  currentItemName: '',
  currentItemGuid: '',
  currentItemHypatiaId: '',
  allowJoin: false,
  voteState: undefined
};

const liveMeeting = createSlice({
  name: 'liveMeeting',
  initialState,
  reducers: {
    setLiveMeeting: (state, { payload: { meetingGuid, votecastAgendaUid } }) => {
      state.meetingGuid = meetingGuid;
      state.votecastAgendaUid = votecastAgendaUid;
    },
    setMemberDetails: (state, { payload }) => {
      state.memberDetails = {
        ...state.memberDetails,
        ...payload,
      };
    },
    setMotionDetails: (state, { payload }) => {
      state.motionDetails = {
        ...state.motionDetails,
        ...payload,
      };
    },
    setAgendaItemsDictionary: (state, { payload }) => {
      state.agendaItemsDictionary = payload;
    },
    setCurrentItem: (
      state,
      { payload: { currentItemName, currentItemGuid, currentItemHypatiaId } }
    ) => {
      state.currentItemName = currentItemName;
      state.currentItemGuid = currentItemGuid;
      state.currentItemHypatiaId = currentItemHypatiaId;
    },
    setIsVotingStarted: (state, { payload }) => {
      state.isVotingStarted = payload;
    },
    setVoteStarted: (state, { payload }) => {
      const voteState = {
        itemText: payload.itemText,
        motionId: payload.motionId,
        voteId: payload.voteId,
        motionText: payload.motionText,
        motionAction: payload.motionAction,
        motionTo: payload.motionTo,
        motionMover: payload.motionMover,
        motionSeconder: payload.motionSeconder,
        ballotId: payload.ballotId,
        votes: [],
        currentVote: null,
      };
      state.voteState = voteState;
    },
    setVotesChanged: (state, { payload }) => {
      //vote: {uid, motion_uid. member_name, member_uid, voting_configuration_name}
      state.voteState.votes = payload;
    },
    setCurrentVote: (state, { payload }) => {
      // {uid, motion_uid. member_name, member_uid, voting_configuration_name}
      if (payload.member_uid === state.memberDetails.memberId) {
        state.voteState.currentVote = payload;
      }

      const index = state.voteState.votes.findIndex(v => v.member_uid === payload.member_uid);
      if (index !== -1) {
        state.voteState.votes[index] = payload;
      } else {
        state.voteState.votes.push(payload);
      }
    },
    withDrawCurrentVote: (state) => {
      const memberId = state.voteState.currentVote.member_uid;
      const index = state.voteState.votes.findIndex(v => v.member_uid === memberId);
      if (index !== -1) {
        state.voteState.votes.splice(index, 1);
      }
      state.voteState.currentVote = null;
    },
    resetVote: (state) => {
      state.voteState.votes = state.voteState.votes.map(vote => ({
        ...vote,
        voting_configuration_name: ""
      }));
      state.voteState.currentVote = null;
    },
    setVoteStop: (state) => {
      state.voteState = null;
    },
    resetData: () => initialState,
  },
});

export default liveMeeting.reducer;

export const {
  setMemberDetails,
  setMotionDetails,
  setAgendaItemsDictionary,
  setIsVotingStarted,
  setLiveMeeting,
  setCurrentItem,
  resetData,
  setVoteStarted,
  setVotesChanged,
  setCurrentVote,
  withDrawCurrentVote,
  setVoteStop,
  resetVote
} = liveMeeting.actions;

/**
 * Summary. Get all live meeting state
 */
export const selectLiveMeeting = (state) => state.liveMeeting;

export const selectMotionState = (state) => {
  const {
    liveMeeting,
    auth: { username },
  } = state;
  return {
    motionId: liveMeeting.motionDetails.motionId,
    motionItemId: liveMeeting.motionDetails.motionItemId,
    username: username,
    allowMotions: liveMeeting.motionDetails.allowMotions,
    motionTo: liveMeeting.motionDetails.motionTo,
    position: liveMeeting.motionDetails.position,
    mover: liveMeeting.motionDetails.motionMover,
    seconder: liveMeeting.motionDetails.motionSeconder,
    previousMotion: liveMeeting.motionDetails.previousMotion,
    userCanMotion:
      liveMeeting.motionDetails.allowMotions &&
      !liveMeeting.motionDetails.motionMover &&
      liveMeeting.memberDetails.isVoter &&
      liveMeeting.memberDetails.isAttendeePresent,
    userCanSecond:
      liveMeeting.motionDetails.allowMotions &&
      !!liveMeeting.motionDetails.motionMover &&
      liveMeeting.motionDetails.motionMover !== username &&
      !liveMeeting.motionDetails.motionSeconder &&
      liveMeeting.memberDetails.isVoter &&
      liveMeeting.memberDetails.isAttendeePresent,
    isUserSubmittedMotion: username === liveMeeting.motionDetails.motionMover,
    isUserSubmittedSecond:
      username === liveMeeting.motionDetails.motionSeconder,
  };
};

export const selectIsVotingStarted = (state) =>
  state.liveMeeting.isVotingStarted;

export const selectVoteState = (state) => state.liveMeeting.voteState;

export const selectVotes = (state) => state.liveMeeting.voteState.votes;
