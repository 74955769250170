import React from 'react';
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Button } from '@trussworks/react-uswds';

const RefreshButton = ({ onClick, text, isLoading, className }) => {

  return (
    <Button type="button" unstyled className={classnames('btn-refresh', className)} onClick={isLoading ? undefined : onClick}>
      <FontAwesomeIcon icon={'redo'} size={'sm'} className={`btn-refresh-icon ${isLoading ? 'fa-spin' : ''}`} />
      {' '}
      {text && <span className='btn-refresh-text'>{text}</span>}
    </Button>
  )
}

export default RefreshButton;

RefreshButton.displayName = 'Refresh Button';

RefreshButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string
};
