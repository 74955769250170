import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Button } from "@trussworks/react-uswds";
import { VoteActionEnum } from "../../../../constants/cloudVotingConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const getTitle = (value) => {
  switch (value) {
    case VoteActionEnum.Yes:
      return "Yay";
    case VoteActionEnum.No:
      return "Nay";
    case VoteActionEnum.Abstain:
      return "Abstain";
    default:
      return "";
  }
};

const VoteButton = ({ value, onClick, selected, isLoading, className, isDisabled = false }) => {
  return (
    <Button
      type="button"
      outline
      onClick={isLoading ? undefined : () => onClick(value)}
      className={classnames({ selected: selected }, className)}
      disabled={isDisabled}
    >
      {getTitle(value)}
      {selected && (
        <div
          className="font-sans-2xs text-gray-90 padding-top-1"
          style={{ fontSize: 14 }}
        >
          <FontAwesomeIcon
            icon={"times"}
            size={"lg"}
            className="margin-right-1"
          />
          Withdraw Vote
        </div>
      )}
    </Button>
  );
};

export default VoteButton;

VoteButton.displayName = "VoteButton";

VoteButton.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  isDisabled: PropTypes.bool
};
