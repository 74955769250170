import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const customerApi = createApi({
  reducerPath: 'customerApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getCustomer: builder.query({
      query: () => '/api/setting/name'
    })
  })
});

export const { useGetCustomerQuery } = customerApi;
