import { combineReducers } from 'redux';
import { agendaApi } from '../services/agendaApi';
import { customerApi } from '../services/customerApi';
import { videoApi } from '../services/videoApi';
import { bookmarkApi } from '../services/bookmarkApi';
import { noteApi } from '../services/noteApi';
import { settingApi } from '../services/settingApi';
import authReducer, { logout } from '../components/Auth/authSlice';
import navigationReducer from '../components/Navigation/navigationSlice';
import syncStatusReducer from '../common/syncStatusSlice';
import settingsStateReducer from '../common/settingSlice';
import { authApi } from '../services/authApi';
import agendaReducer from '../components/Agendas/agendaSlice';
import activeAgendaReducer from '../components/ActiveAgenda/activeAgendaSlice';
import currentPageReducer from '../common/currentPageSlice';
import videoReducer from '../components/Videos/videoSlice'
import noteReducer from '../components/Notes/noteSlice'
import bookmarkReducer from '../components/Bookmarks/bookmarkSlice'
import liveMeetingReducer from '../common/liveMeetingSlice';
import liveMeetingSpeakerReducer from '../common/liveMeetingSpeakerSlice';

const appReducer = combineReducers({
  [agendaApi.reducerPath]: agendaApi.reducer,
  [bookmarkApi.reducerPath]: bookmarkApi.reducer,
  [noteApi.reducerPath]: noteApi.reducer,
  [customerApi.reducerPath]: customerApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [videoApi.reducerPath]: videoApi.reducer,
  [settingApi.reducerPath]: settingApi.reducer,
  auth: authReducer,
  navigation: navigationReducer,
  syncStatus: syncStatusReducer,
  agendas: agendaReducer,
  activeAgenda: activeAgendaReducer,
  currentPage: currentPageReducer,
  videos: videoReducer,
  notes: noteReducer,
  bookmarks: bookmarkReducer,
  settingsState: settingsStateReducer,
  liveMeeting: liveMeetingReducer,
  liveMeetingSpeaker:liveMeetingSpeakerReducer
});

const rootReducer = (state, action) => {
  if (action.type === logout.type) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
