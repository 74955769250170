// Import to initialize BugSnag ASAP - before other imports
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

// Only start bugsnag if we've got the environment key, so we don't have to leave a fallback
// key in the code (*cough* *cough* SECURITY *cough* *cough*)
Bugsnag.start({
  apiKey:
    process.env.FRONTEND_BUGSNAG_API_KEY || 'eb73322dd43f9b2b771146832030d24f',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['qa', 'dev', 'int', 'production'],
  releaseStage: process.env.APP_ENV || 'local', // corresponds to qa/dev/int/production, set by PE/chef/atlas in the ENV
});

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'es6-symbol';
import 'url-polyfill';

import '@trussworks/react-uswds/lib/uswds.css';
import '@trussworks/react-uswds/lib/index.css';
import 'stylesheets/legislate/legislate';

import React from 'react';
import ReactDOM from 'react-dom';
import registerIconLibrary from '../../assets/icons/legislate/iconIndex';
import { Provider } from 'react-redux';

registerIconLibrary();

// import App from 'legislate/components/App';
import App from '../legislate/app/App';
import store from '../legislate/app/store';
import FallbackComponent from '../legislate/components/Common/FallbackComponent';

// PLEASE NOTE --> we believe that webpacker searches the javascript/packs for *.js files,
// which are treated as 'entry' points in the eyes of webpack, and will compile your entry point file
// into a <name>-<hash>.js file under the public output folder. from there, if you import stylesheets
// along with the entrypoint js file, webpacker will also generate a <name>-<hash>.css file output that
// matches your entry point.

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary FallbackComponent={FallbackComponent}>
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('legislate')
);
