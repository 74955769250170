/**
 * Display a count down timer by second, use start_time and duration
 */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { secondsToTimeString } from '../../../../utils/dateTimeUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Progress } from 'antd';
import { selectSelectedItemKey } from '../../ActiveAgenda/activeAgendaSlice';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const CalledSpeaker = () => {
  const [remainingTime, setRemainingTime] = useState(0);

  const calledSpeaker = useSelector((state) => state.liveMeetingSpeaker.calledSpeaker);
  const selectedItemKey = useSelector(selectSelectedItemKey);
  const itemsDictionary = useSelector(state => state.liveMeeting.agendaItemsDictionary);
  const selectedItemHypatia = itemsDictionary[selectedItemKey];
  const start_time = dayjs.utc(calledSpeaker?.start_time);

  const duration = calledSpeaker?.duration;
  const isSpeaking =
    !!calledSpeaker?.name && selectedItemHypatia?.uid === calledSpeaker?.item_uid;

  useEffect(() => {
    const interval = setInterval(() => {
      const elapsedTime = Math.floor((dayjs().utc() - start_time) / 1000);
      const timeLeft = duration - elapsedTime;
      setRemainingTime(timeLeft > 0 ? timeLeft : 0);
    }, 1000);

    return () => clearInterval(interval);
  }, [start_time, duration]);

  return (
    <>
      <div className="speaker-called display-flex">
        <div className="flex-1">
          <div className="speaker-called-name">
            <FontAwesomeIcon icon="user" size="lg" />
            <div>
              <div className="sub-title">
                {isSpeaking ? calledSpeaker?.name : 'No one is speaking'}
              </div>
              <div className="sub-text">Current Speaker</div>
            </div>
          </div>
        </div>

        {isSpeaking && (
          <div className="flex-auto">
            <div className="sub-title">
              {secondsToTimeString(remainingTime)}
            </div>
            <div className="sub-text">Time Remaining</div>
          </div>
        )}
      </div>
      {isSpeaking && (
        <Progress
          percent={((duration - remainingTime) / duration) * 100}
          showInfo={false}
          strokeColor="#99bfdb"
          trailColor="#005EA2"
          className="progress-bar"
        />
      )}
    </>
  );
};

export default CalledSpeaker;
