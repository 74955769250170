import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetActiveAgendaQuery } from "../../services/agendaApi";
import { useGetActiveAgendaBookmarksQuery } from "../../services/bookmarkApi";
import { useGetActiveAgendaNotesQuery } from "../../services/noteApi";
import { useGetCurrentUserQuery /*, useGetCurrentPersonUidQuery */ } from '../../services/authApi';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  setActiveAgenda,
  setItemKeys,
  setItemsDictionary,
  setSelectedItemKey,
  resetData,
  selectEnableErrorNotification,
  setEnableActiveAgendaErrorNotification
} from "./activeAgendaSlice";
import { Card, CardGroup, Button } from "@trussworks/react-uswds";
import AgendaItems from "./AgendaItems";
import { buildAgendaData, validatePacketUrl } from "../../helpers/agendaHelper";
import { getDateInOffsetTime } from "../../helpers/dayjsHelpers";
import settings from "../../common/settings";
import Breadcrumb from "../Navigation/Breadcrumb";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { selectIsCloudVotingEnabled } from "../../common/settingSlice";
import { setCustomerId, setUsername } from '../Auth/authSlice';
import JoinMeetingButton from "../Common/JoinMeetingButton";
import LeaveMeetingButton from "../Common/LeaveMeeting";
import { CvMeetingLiveIcon } from "../../../../assets/images/legislate/imageIndex";
import classNames from "classnames";
import {
  selectLiveMeeting,
  setCurrentItem,
  setLiveMeeting,
  resetData as resetLiveMeetingData,
  setMotionDetails,
  setAgendaItemsDictionary,
  setVoteStop,
  setIsVotingStarted,
  setMemberDetails,
  selectVoteState,
  setVotesChanged,
  setCurrentVote,
  setVoteStarted
} from "../../common/liveMeetingSlice";
import { setSpeakers, setCalledSpeaker, deleteSpeaker } from '../../common/liveMeetingSpeakerSlice';
import {
  MeetingStatus, AvailabilityStatus, MemberAttendance, VotecastRequestOrigin, AgendaItemActiveStatus,
  AgendaItemType, SpeakerStatus, VotecastBulkAction, VoteActionEnum
} from "../../../constants/cloudVotingConstants";
import GraphQLAPI, { graphqlOperation } from '@aws-amplify/api';
import {
  getMembersByDisplayName,
  getVotecastAgendaItems,
  getMotionByAgendaItemId,
  getAgendaListByMeetingGuid,
  getVotecastSpeakersByAgendaId,
  getActiveAgendaItem,
  getBallotByParentItemId,
  getVotecastAgendaItemsByParentUid,
  getVotecastMotionById
} from '../../../../amplify/graphql/queries';
import { updateMemberAvailabilityStatus } from '../../../../amplify/graphql/mutations';
import ErrorNotification from '../Common/ErrorNotification';
import {
  onAgendaItemRecord,
  onMeetingEndUpdate,
  onVotecastMemberUpdate,
  onVotecastCreateDeleteSpeaker,
  onVotecastDeleteAttendee,
  onMotionUpdate,
  onBallotUpdate,
  onCreateUpdateAgendaItemForVote,
  onVotecastDeleteAgendaItem
} from '../../../../amplify/graphql/subscriptions';
import { toast } from 'react-toastify';
import Container from "./ItemDetail/Container";
import { invokeGraphqlOperation, invokeGraphqlOperationWithNextToken } from '../../../helpers/votecastWebHelpers/graphqlOperations';

const ActiveAgenda = () => {
  const dispatch = useDispatch();
  const { agenda_uid: agendaUid, item_uid: itemUid } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isItemSelected, setIsItemSelected] = useState(false);
  const [treeData, setTreeData] = useState(undefined);

  const [isMeetingOpenOrRunning, setIsMeetingOpenOrRunning] = useState(false);
  const [isMeetingRunning, setIsMeetingRunning] = useState(false);
  const [memberId, setMemberId] = useState('');
  const personUid = useSelector(state => state.auth.personUid);
  const customerId = useSelector(state => state.auth.customer_uid);
  const userName = useSelector(state => state.auth.username);
  const speakers = useSelector((state) => state.liveMeetingSpeaker.speakers);
  const calledSpeaker = useSelector(state => state.liveMeetingSpeaker.calledSpeaker);
  const voteState = useSelector(selectVoteState);

  const {
    data: agenda,
    isSuccess: isAgendaSuccess,
    isError: isAgendaError,
  } = useGetActiveAgendaQuery(agendaUid);

  const {
    data: bookmarks,
    isSuccess: isBookmarksSuccess,
    isError: isBookmarksError,
  } = useGetActiveAgendaBookmarksQuery(agendaUid);

  const votecast_enableErrorNotification = useSelector(selectEnableErrorNotification);

  const {
    data: notes,
    isSuccess: isNotesSuccess,
    isError: isNotesError,
  } = useGetActiveAgendaNotesQuery(agendaUid);

  const isCloudVotingEnabled = useSelector(selectIsCloudVotingEnabled);
  const {
    data: currentUserData,
    isSuccess: isCurrentUserSuccess,
    isError: isCurrentUserError,
  } = useGetCurrentUserQuery(undefined, { skip: !isCloudVotingEnabled });

  useEffect(() => {
    if (isCloudVotingEnabled && isCurrentUserSuccess) {
      dispatch(setCustomerId(currentUserData?.customer_uid));
      dispatch(setUsername(currentUserData?.user?.table?.fullname));
    }
  }, [isCloudVotingEnabled, isCurrentUserSuccess])

  /* Commenting out fetching and setting of current person UID based on user name
  const currentUserName = currentUserData ? currentUserData.user?.table?.fullname : '';
  const {
    data: currentPersonUidData,
    isSuccess: isCurrentPersonUidSuccess,
    isError: isCurrentPersonUidError,
  } = useGetCurrentPersonUidQuery(currentUserName, {
    skip: !isCloudVotingEnabled || !currentUserName,
  });
  useEffect(() => {
    if (cloudVotingEnabled && isCurrentPersonUidSuccess ) {
      dispatch(setPersonUid(currentPersonUidData[0]?.uuid));
    }
  }, [isCloudVotingEnabled, isCurrentPersonUidSuccess])*/

  const liveMeeting = useSelector(selectLiveMeeting);
  const votecastAgendaUid = liveMeeting.votecastAgendaUid;
  const currentItemHypatiaId = liveMeeting.currentItemHypatiaId;

  const onJoinMeeting = async (votecastAgendaUid) => {
    const agendaItemsResponse = await GraphQLAPI.graphql(graphqlOperation(getVotecastAgendaItems, { agenda_uid: votecastAgendaUid }));
    const agendaItemsData = agendaItemsResponse?.data?.getVotecastAgendaItems;
    if (agendaItemsData && agendaItemsData.length > 0) {
      const agendaItemsDictionary = agendaItemsData.reduce((acc, item) => {
        acc[item.mema_metadata_id] = item;
        return acc;
      }, {});
      dispatch(setAgendaItemsDictionary(agendaItemsDictionary))

      // get and set the active agenda item
      const activeAgendaItem = agendaItemsData.find(item => item.status === AgendaItemActiveStatus.ACTIVE);
      if (activeAgendaItem) {
        dispatch(setCurrentItem({
          currentItemGuid: activeAgendaItem.mema_metadata_id,
          currentItemName: activeAgendaItem.title,
          currentItemHypatiaId: activeAgendaItem.uid
        }));
        dispatch(setMotionDetails({ allowMotions: true }));

        const activeMotionItem = agendaItemsData.find(item => item.type === AgendaItemType.Motion
          && !item.entrytime && item.parent_uid === activeAgendaItem.uid);
        if (activeMotionItem) {
          const motionByMotionItemResponse = await GraphQLAPI.graphql(graphqlOperation(getMotionByAgendaItemId,
            { agenda_uid: votecastAgendaUid, parent_agenda_item_uid: activeMotionItem.uid }));
          const activeMotionData = motionByMotionItemResponse?.data?.getVotecastMotionByAgendaItemId;

          if (activeMotionData && activeMotionData.length > 0) {
            const motionInProgress = activeMotionData[0];
            if (motionInProgress) {
              dispatch(setMotionDetails({
                motionId: motionInProgress.uid,
                position: motionInProgress.position,
                motionTo: motionInProgress.motion_action_name,
                motionMover: motionInProgress.motioner_name,
                motionSeconder: motionInProgress.seconder_name
              }));
            }
          }
        }
        // Find the previous motion with the latest Timestamped Motion
        const motionItemsWithEntryTime = agendaItemsData.filter(item => item.type === AgendaItemType.Motion
          && item.entrytime && item.parent_uid === activeAgendaItem.uid);
        if (motionItemsWithEntryTime && motionItemsWithEntryTime.length > 0) {
          const previousMotionItem = motionItemsWithEntryTime.reduce((max, motion) => {
            return motion.entrytime > max.entrytime ? motion : max;
          }, motionItemsWithEntryTime[0]);
          if (previousMotionItem) {
            const motionByMotionItemResponse = await GraphQLAPI.graphql(graphqlOperation(getMotionByAgendaItemId,
              { agenda_uid: votecastAgendaUid, parent_agenda_item_uid: previousMotionItem.uid }));
            const previousMotionData = motionByMotionItemResponse?.data?.getVotecastMotionByAgendaItemId;

            if (previousMotionData && previousMotionData.length > 0) {
              const previousMotion = previousMotionData[0];
              dispatch(setMotionDetails({
                previousMotion: {
                  motionTo: previousMotion.motion_action_name,
                  mover: previousMotion.motioner_name,
                  seconder: previousMotion.seconder_name
                }
              }));
            }
          }
        }
      }
    }

    // get the list of speakers for agenda_id
    const speakersData = await invokeGraphqlOperationWithNextToken(getVotecastSpeakersByAgendaId, { agenda_uid: votecastAgendaUid });
    if (speakersData && speakersData.length > 0) {
      dispatch(setSpeakers(speakersData));
      dispatch(setCalledSpeaker(speakersData.find(sp => sp.status && [SpeakerStatus.CALLED, SpeakerStatus.STARTED].includes(sp.status))));
    }
  };

  const testOnLeaveMeeting = () => {
    dispatch(resetLiveMeetingData());
  };

  const testVoteStop = () => {
    dispatch(setVoteStop);
  };

  // CV TEMP TO BE CHANGED - END

  useEffect(() => {
    if (isAgendaSuccess && isBookmarksSuccess && isNotesSuccess) {
      dispatch(
        setActiveAgenda([
          agenda,
          notes.councilNotesByItemUid,
          bookmarks.bookmarkedItemUids,
        ])
      );

      // combine & build tree data
      const [treeData, itemsDict, keys] = buildAgendaData(
        agenda,
        notes.councilNotesByItemUid,
        bookmarks.bookmarkedItemUids
      );

      setTreeData(treeData);

      dispatch(setItemKeys(keys));

      // build agenda items dictionary to improve the performance while navigating items (next/prev)
      dispatch(setItemsDictionary(itemsDict));

      if (!isItemSelected) {
        setIsItemSelected(true);
        let currentItem;

        if (itemUid) {
          currentItem = itemUid; //if params has itemUid
        } else if (
          liveMeeting.meetingGuid === agendaUid &&
          !!liveMeeting.currentItemGuid
        ) {
          currentItem = liveMeeting.currentItemGuid; //focus to current live Item - realtime
        } else {
          const firstItemKey = keys.length > 0 ? keys[0] : null;
          if (firstItemKey) {
            currentItem = firstItemKey;
          }
        }

        dispatch(setSelectedItemKey(currentItem));
      }
      setIsLoading(false);
    }
  }, [
    agendaUid,
    isAgendaSuccess,
    isBookmarksSuccess,
    isNotesSuccess,
    bookmarks?.bookmarkedItemUids.length,
    notes?.councilNotesByItemUid,
  ]);

  useEffect(() => {
    let isCancelled = false;
    const fetchMeetingData = async () => {
      if (isCloudVotingEnabled && customerId) {
        const getMeetingResponse =
          await GraphQLAPI.graphql(graphqlOperation(getAgendaListByMeetingGuid, { customer_uid: customerId, meeting_guid: agendaUid }));
        const meetingData = getMeetingResponse?.data?.getVotecastAgendaByMeetingGuid;
        const filteredAndSortedMeetingData = meetingData
          ?.filter(item => item.meeting_status === MeetingStatus.OPEN || item.meeting_status === MeetingStatus.RUNNING)
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        const liveVotecastAgendaUid = filteredAndSortedMeetingData?.[0]?.uid ?? null;
        dispatch(setLiveMeeting({ meetingGuid: agendaUid, votecastAgendaUid: liveVotecastAgendaUid }));
        if (filteredAndSortedMeetingData?.length > 0) {
          if (!isCancelled) {
            setIsMeetingOpenOrRunning(true);
            if (filteredAndSortedMeetingData[0].meeting_status === MeetingStatus.RUNNING) {
              setIsMeetingRunning(true);
            } else {
              setIsMeetingRunning(false);
            }
          }
          const members = await GraphQLAPI.graphql(graphqlOperation(getMembersByDisplayName,
            { customer_uid: customerId, agenda_uid: liveVotecastAgendaUid, person_uid: personUid, display_name: userName }));
          const memberData = members?.data?.getVotecastMembersByDisplayName;
          let personIdMatch = false;
          if (memberData && memberData.length > 0) {
            for (const member of memberData) {
              if (member.person_uid === personUid || member.display_name === userName) {
                if (!isCancelled) {
                  setMemberId(member.uid);
                  dispatch(
                    setMemberDetails({
                      memberId: member.uid,
                      memberJoinStatus: member.availability_status === AvailabilityStatus.JOINED,
                      isAttendeePresent: member.status && member.status.toLowerCase() === MemberAttendance.PRESENT,
                      isChair: member.chair,
                      isVoter: member.voting,
                    }));
                  if (member.availability_status === AvailabilityStatus.JOINED) {
                    onJoinMeeting(liveVotecastAgendaUid);
                  }
                }
                personIdMatch = true;
                break;
              }
            }
            if (!personIdMatch && !isCancelled) {
              setMemberId(memberData[0].uid);
              dispatch(setMemberDetails({
                memberId: memberData[0].uid,
                memberJoinStatus: memberData[0].availability_status === AvailabilityStatus.JOINED,
                isAttendeePresent: memberData[0].status && memberData[0].status.toLowerCase() === MemberAttendance.PRESENT,
                isChair: memberData[0].chair,
                isVoter: memberData[0].voting
              }));
              if (memberData[0].availability_status === AvailabilityStatus.JOINED) {
                onJoinMeeting(liveVotecastAgendaUid);
              }
            }
          }
        } else {
          setIsMeetingOpenOrRunning(false);
          setIsMeetingRunning(false);
        }
      }
    };
    fetchMeetingData();
    return () => {
      isCancelled = true;
    };
  }, [isCloudVotingEnabled, customerId, agendaUid, personUid, userName]);

  useEffect(() => {
    if (isCloudVotingEnabled && customerId && votecastAgendaUid) {
      const subscription = GraphQLAPI.graphql(graphqlOperation(onAgendaItemRecord,
        { customer_uid: customerId, agenda_uid: votecastAgendaUid, status: "Active" }
      )).subscribe({
        next: async (response) => {
          const agendaItemData = response.value.data.onVotecastAgendaItemRecord;

          // Get all motions for active agenda item
          const motionsForActiveAgendaItemResponse = await GraphQLAPI.graphql(graphqlOperation(getVotecastAgendaItemsByParentUid, { parent_uid: agendaItemData.uid, type: AgendaItemType.Motion }));
          const motionsForActiveAgendaItem = motionsForActiveAgendaItemResponse?.data?.getVotecastAgendaItemsByParentUid ?? [];
          const latestRecordedMotionItem = motionsForActiveAgendaItem
          .filter(motion => motion.entrytime)
          .sort((a, b) => b.position - a.position)[0];
          let latestRecordedMotionDetail = null;
          if (latestRecordedMotionItem) {
            const motionUid = JSON.parse(latestRecordedMotionItem.metadata);
            if (motionUid?.motion_id) {
              const motionDetailResponse = await GraphQLAPI.graphql(graphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: motionUid.motion_id }));
              latestRecordedMotionDetail = motionDetailResponse?.data?.getVotecastMotionById;
            }
          }

          if (agendaItemData && agendaItemData.mema_metadata_id) {
            if (currentItemHypatiaId !== agendaItemData.uid) {
              dispatch(setMotionDetails({
                allowMotions: true, isMotionEnabled: false,
                motionId: '',
                motionItemId: '',
                position: '',
                motionTo: '',
                motionMover: '',
                motionSeconder: '',
                previousMotion: latestRecordedMotionDetail !== null ? {
                  motionTo: latestRecordedMotionDetail?.motion_action_name,
                  mover: latestRecordedMotionDetail?.motioner_name,
                  seconder: latestRecordedMotionDetail?.seconder_name
                } : undefined
              }));
              dispatch(setIsVotingStarted(false));
              dispatch(setVoteStop());
            }
            dispatch(setCurrentItem({
              currentItemGuid: agendaItemData.mema_metadata_id,
              currentItemName: agendaItemData.title,
              currentItemHypatiaId: agendaItemData.uid
            }));
          }
        },
        error: (error) => {
          console.error('Subscription error:', error);
        }
      });
      return () => subscription.unsubscribe();
    }
  }, [isCloudVotingEnabled, customerId, votecastAgendaUid, liveMeeting.currentItemHypatiaId]);

  useEffect(() => {
    if (isCloudVotingEnabled && votecastAgendaUid && customerId) {
      let speaker_subscription;
      const subscribeToSpeaker = () => {
        speaker_subscription = GraphQLAPI.graphql(graphqlOperation(onVotecastCreateDeleteSpeaker,
          { customer_uid: customerId, agenda_uid: liveMeeting.votecastAgendaUid }
        )).subscribe({
          next: (response) => {
            const subscribedData = response.value.data.onVotecastCreateDeleteSpeaker;
            if (subscribedData) {
              if (subscribedData.request_origin === VotecastRequestOrigin.ATTENDEE_CREATE && subscribedData.name !== userName) {
                const requestedSpeaker = {
                  uid: subscribedData.uid,
                  name: subscribedData.name,
                  speaker_type: subscribedData.speaker_type,
                  duration: subscribedData.duration,
                  position: subscribedData.position,
                  item_uid: subscribedData.item_uid
                };
                dispatch(setSpeakers(speakers.concat(requestedSpeaker)));
              }
              else if ([VotecastRequestOrigin.CLERK_DELETE, VotecastRequestOrigin.ATTENDEE_DELETE].includes(subscribedData.request_origin)) {
                dispatch(deleteSpeaker(subscribedData.uid));
                if (calledSpeaker && subscribedData.uid === calledSpeaker.uid) {
                  dispatch(setCalledSpeaker(undefined));
                }
              }
              else if ([VotecastRequestOrigin.ATTENDEE_CHAIR_UPDATE, VotecastRequestOrigin.CLERK_UPDATE].includes(subscribedData.request_origin)) {
                if (subscribedData.status === SpeakerStatus.CALLED) {
                  dispatch(setCalledSpeaker(subscribedData));
                  dispatch(deleteSpeaker(subscribedData.uid));
                }
                else if (subscribedData.status === SpeakerStatus.STARTED) {
                  dispatch(setCalledSpeaker({
                    ...calledSpeaker,
                    duration: subscribedData.duration, start_time: subscribedData.start_time, status: subscribedData.status
                  }))
                }
              }
            }
          },
          error: (error) => {
            console.error('Subscription: onVotecastCreateDeleteSpeaker error:', error);
            setTimeout(() => {
              console.log('Reconnecting onVotecastCreateDeleteSpeaker subscription...');
              subscribeToSpeaker();
            }, 5000);
          }
        });
      }
      subscribeToSpeaker();
      return () => {
        if (speaker_subscription) {
          speaker_subscription.unsubscribe();
        }
      };
    }
  }, [isCloudVotingEnabled, customerId, votecastAgendaUid, speakers]);

  useEffect(() => {
    if (customerId && votecastAgendaUid) {
      const subscription = GraphQLAPI.graphql(graphqlOperation(onMotionUpdate,
        { customer_uid: customerId, agenda_uid: votecastAgendaUid }
      )).subscribe({
        next: (response) => {
          const motionData = response.value.data.onVotecastMotionUpdate;
          if (motionData && motionData.request_origin === VotecastRequestOrigin.CLERK_CREATE) {
            dispatch(setMotionDetails({
              motionId: motionData.uid,
              motionTo: motionData.motion_action_name,
              motionItemId: motionData.parent_agenda_item_uid
            }));
          }
          else {
            if (motionData && motionData.uid === liveMeeting?.motionDetails.motionId) {
              // If request if from clerk side and entrytime is present in the response. => Clerk has recorded/completed the motion.
              if (motionData.entrytime && motionData.request_origin === VotecastRequestOrigin.CLERK_UPDATE) {
                dispatch(setMotionDetails({
                  motionId: '',
                  motionItemId: '',
                  motionTo: '',
                  motionMover: '',
                  motionSeconder: '',
                  previousMotion: {
                    motionTo: motionData.motion_action_name,
                    mover: motionData.motioner_name,
                    seconder: motionData.seconder_name
                  }
                }));

                if (motionData.motion_result_name) {
                  // ToDo : Open a modal to show the vote result
                  console.log("Voting Result:", motionData.motion_result_name);
                }
              }
            }
            if (motionData && motionData.uid && !motionData.entrytime) {
              if (motionData.motioner_name != null) {
                dispatch(setMotionDetails({ motionMover: motionData.motioner_name }));
              }
              if (motionData.seconder_name != null) {
                dispatch(setMotionDetails({ motionSeconder: motionData.seconder_name }));
              }
              dispatch(setMotionDetails({
                isMotionEnabled: true,
                motionItemId: motionData.parent_agenda_item_uid,
                motionId: motionData.uid,
                motionTo: motionData.motion_action_name,
                position: motionData.position
              }));
            }
          }
        },
        error: (error) => {
          console.error('Subscription: onMotionUpdate error:', error);
        }
      });
      return () => subscription.unsubscribe();
    }
  }, [customerId, votecastAgendaUid, liveMeeting?.motionDetails?.motionId]);

  // Appsync graphql subscription- delete motion and Votes
  useEffect(() => {
    if (liveMeeting.votecastAgendaUid) {
      const vote_subscription = GraphQLAPI.graphql(graphqlOperation(onVotecastDeleteAgendaItem, { agenda_uid: liveMeeting.votecastAgendaUid }
      )).subscribe({
        next: async (response) => {
          const deleteAgendaItemData = response.value.data.onVotecastDeleteAgendaItem;

          // Get all motions for active agenda item
          const motionsForActiveAgendaItemResponse = await GraphQLAPI.graphql(graphqlOperation(getVotecastAgendaItemsByParentUid, { parent_uid: currentItemHypatiaId, type: AgendaItemType.Motion }));
          const motionsForActiveAgendaItem = motionsForActiveAgendaItemResponse?.data?.getVotecastAgendaItemsByParentUid ?? [];
          const latestRecordedMotionItem = motionsForActiveAgendaItem
          .filter(motion => motion.entrytime)
          .sort((a, b) => b.position - a.position)[0];

          let latestRecordedMotionDetail = null;
          if (latestRecordedMotionItem) {
            const motionUid = JSON.parse(latestRecordedMotionItem.metadata);
            if (motionUid?.motion_id) {
              const motionDetailResponse = await GraphQLAPI.graphql(graphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: motionUid.motion_id }));
              latestRecordedMotionDetail = motionDetailResponse?.data?.getVotecastMotionById;
            }
          }
          if (deleteAgendaItemData) {
            if (deleteAgendaItemData.type === AgendaItemType.Motion) {
              dispatch(setMotionDetails(
                {
                  previousMotion: latestRecordedMotionDetail !== null ? {
                    motionTo: latestRecordedMotionDetail?.motion_action_name,
                    mover: latestRecordedMotionDetail?.motioner_name,
                    seconder: latestRecordedMotionDetail?.seconder_name
                  } : undefined
                }
              ));
              const metadata = JSON.parse(deleteAgendaItemData?.metadata);
              if (metadata?.motion_id === liveMeeting?.motionDetails.motionId) {
                dispatch(setMotionDetails({
                  motionId: '',
                  motionTo: '',
                  motionMover: '',
                  motionSeconder: '',
                }));
                // Also reset the related vote
                if (voteState && metadata?.motion_id === voteState?.motionId) {
                  dispatch(setVoteStop());
                }
              }
            } else if (voteState && deleteAgendaItemData.uid === voteState?.voteId && deleteAgendaItemData.type === AgendaItemType.Vote) {
              dispatch(setVoteStop());
            }
          }
        },
        error: (error) => {
          console.error('Subscription: onVotecastDeleteAgendaItem error:', error);
        }
      });
      return () => vote_subscription.unsubscribe();
    }
  }, [liveMeeting.votecastAgendaUid, voteState?.motionId, voteState?.voteId, liveMeeting?.motionDetails.motionId]);

  useEffect(() => {
    const startSubscription = async () => {
      try {
        const vote_subscription = await GraphQLAPI.graphql(graphqlOperation(onCreateUpdateAgendaItemForVote,
          { customer_uid: customerId, agenda_uid: votecastAgendaUid, type: AgendaItemType.Vote }
        )).subscribe({
          next: async (response) => {
            const voteAgendaItemData = response.value.data.onVotecastCreateUpdateAgendaItemForVote;
            if (voteAgendaItemData) {
              if (voteState && voteAgendaItemData.uid === voteState?.voteId) {
                if (voteAgendaItemData.bulk_action === VotecastBulkAction.CLERK_MARK_VOTES_ALL_YES) {
                  const ballotsData = await invokeGraphqlOperation(getBallotByParentItemId, { parent_agenda_item_uid: voteAgendaItemData.uid });
                  if (ballotsData && ballotsData.length > 0) {
                    dispatch(setVotesChanged(
                      ballotsData.map(vote => ({
                        ...vote,
                        voting_configuration_name: VoteActionEnum.Yes
                      }))
                    ));
                    const currentUserVote = ballotsData.find(vote => vote.member_uid === liveMeeting.memberDetails.memberId);
                    dispatch(setCurrentVote({ ...currentUserVote, voting_configuration_name: VoteActionEnum.Yes }));
                  }
                }
                else if ([VotecastBulkAction.ATTENDEE_CHAIR_RESET_VOTES, VotecastBulkAction.CLERK_RESET_VOTES].includes(voteAgendaItemData.bulk_action)) {
                  const ballotsData = await invokeGraphqlOperation(getBallotByParentItemId, { parent_agenda_item_uid: voteAgendaItemData.uid });
                  if (ballotsData && ballotsData.length > 0) {
                    dispatch(setVotesChanged(
                      ballotsData.map(vote => ({
                        ...vote,
                        voting_configuration_name: ''
                      }))
                    ));
                    const currentUserVote = ballotsData.find(vote => vote.member_uid === liveMeeting.memberDetails.memberId);
                    dispatch(setCurrentVote({ ...currentUserVote, voting_configuration_name: '' }));
                  }
                }
                else if (voteAgendaItemData.entrytime) {
                  dispatch(setIsVotingStarted(false));
                  dispatch(setVoteStop());
                }
              }
              else if (voteAgendaItemData.bulk_action === VotecastBulkAction.CLERK_VOTE_BULK_CREATE) {
                const activeAgendaItemData = await invokeGraphqlOperation(getActiveAgendaItem, { agenda_uid: votecastAgendaUid });
                const ballotsData = await invokeGraphqlOperation(getBallotByParentItemId, { parent_agenda_item_uid: voteAgendaItemData.uid });
                if (ballotsData && ballotsData.length > 0) {
                  const motionData = await invokeGraphqlOperation(getVotecastMotionById, { agenda_uid: votecastAgendaUid, uid: ballotsData[0].motion_uid });
                  if (motionData) {
                    const metadata = JSON.parse(activeAgendaItemData?.metadata ?? "{}");
                    const userVote = ballotsData.find(vote => vote.member_uid === liveMeeting.memberDetails.memberId);
                    dispatch(setVoteStarted({
                      itemText: activeAgendaItemData?.title,
                      voteId: voteAgendaItemData.uid,
                      motionId: motionData.uid,
                      motionText: motionData?.text ?? "",
                      motionAction: metadata?.suggested_action ?? "",
                      motionTo: motionData?.motion_action_name ?? "",
                      motionMover: motionData?.motioner_name ?? "",
                      motionSeconder: motionData?.seconder_name ?? "",
                      ballotId: userVote?.uid
                    }));
                    dispatch(setIsVotingStarted(true));
                    dispatch(setVotesChanged(ballotsData));
                  }
                }
              }
            }
          },
          error: (error) => {
            console.error('Subscription: onCreateUpdateAgendaItemForVote error:', error);
          }
        });

        return () => vote_subscription.unsubscribe();
      } catch (error) {
        console.error('Error initializing subscription:', error);
      }
    };
    if (customerId && votecastAgendaUid && liveMeeting.memberDetails.memberId) {
      startSubscription();
    }
  }, [customerId, votecastAgendaUid, liveMeeting.memberDetails.memberId, voteState?.voteId]);

  useEffect(() => {
    if (itemUid) {
      dispatch(setSelectedItemKey(itemUid));
    }
  }, [dispatch, itemUid]);

  useEffect(() => { }, [agenda?.name]);

  useEffect(() => {
    // Will fetch the real userGuid from graphql
    // dispatch(setPersonUid("b1b5fc14-a58a-40b0-a93f-5010d9e7874c")); // CV TEMP TO BE CHANGED

    // returned function will be called on component unmount
    return () => {
      dispatch(resetData());
    };
  }, []);

  const toastConfig = {
    className: "live-meeting-toast",
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    closeButton: false,
    onClose: () => window.location.reload(), // Redirect on toast close
    style: { width: '475px' }
  };

  useEffect(() => {
    if (isCloudVotingEnabled && votecastAgendaUid) {
      const stopMeeting_subscription = GraphQLAPI.graphql(graphqlOperation(onMeetingEndUpdate,
        { uid: votecastAgendaUid }
      )).subscribe({
        next: (response) => {
          const votecastMeetingData = response.value.data.onVotecastStopMeeting;
          if (votecastMeetingData && votecastMeetingData.uid === votecastAgendaUid && liveMeeting.memberDetails.memberJoinStatus) {
            toast("Clerk has ended the meeting. The agenda is no longer live.", toastConfig);
          }
        },
        error: (error) => {
          console.error('Subscription error:', error);
        }
      });
      return () => stopMeeting_subscription.unsubscribe();
    }
  }, [isCloudVotingEnabled, liveMeeting.memberDetails.memberJoinStatus, votecastAgendaUid]);

  useEffect(() => {
    if (isCloudVotingEnabled && customerId && liveMeeting.memberDetails.memberId && liveMeeting.memberDetails.memberJoinStatus) {
      const deleteAttendee_subscription = GraphQLAPI.graphql(graphqlOperation(onVotecastDeleteAttendee,
        { uid: liveMeeting.memberDetails.memberId, customer_uid: customerId }
      )).subscribe({
        next: (response) => {
          const votecastDeletedMemberData = response.value.data.onVotecastDeleteMember;
          if (votecastDeletedMemberData && votecastDeletedMemberData.uid === liveMeeting.memberDetails.memberId) {
            toast("You have been removed from the meeting. This agenda is no longer live for you.", toastConfig);
          }
        },
        error: (error) => {
          console.error('Subscription error:', error);
        }
      });
      return () => deleteAttendee_subscription.unsubscribe();
    }
  }, [isCloudVotingEnabled, liveMeeting.memberDetails.memberJoinStatus, liveMeeting.memberDetails.memberId]);

  useEffect(() => {
    if (isCloudVotingEnabled && customerId && votecastAgendaUid && memberId) {
      const memberUpdateSubscription = GraphQLAPI.graphql(graphqlOperation(onVotecastMemberUpdate,
        { uid: memberId, customer_uid: customerId, agenda_uid: votecastAgendaUid }
      )).subscribe({
        next: (response) => {
          const responseMemberDetails = response.value.data.onVotecastMemberUpdate;
          dispatch(setMemberDetails({
            isAttendeePresent: (responseMemberDetails.status && responseMemberDetails.status.toLowerCase() === MemberAttendance.PRESENT),
            isVoter: responseMemberDetails.voting,
            isChair: responseMemberDetails.chair
          }))
        },
        error: (error) => {
          console.error('memberUpdateSubscription error:', error);
        }
      });
      return () => memberUpdateSubscription.unsubscribe();
    }
  }, [customerId, votecastAgendaUid, memberId]);

  useEffect(() => {
    if (isCloudVotingEnabled && customerId && votecastAgendaUid && voteState && voteState?.voteId) {
      const vote_subscription = GraphQLAPI.graphql(graphqlOperation(onBallotUpdate,
        { customer_uid: customerId, agenda_uid: votecastAgendaUid }
      )).subscribe({
        next: (response) => {
          const updateVoteData = response.value.data.onVotecastUpdateBallot;
          if (updateVoteData && updateVoteData.parent_agenda_item_uid === voteState.voteId) {
            const voter = {
              uid: updateVoteData.uid,
              motion_uid: updateVoteData.motion_uid,
              member_name: updateVoteData.member_name,
              member_uid: updateVoteData.member_uid,
              voting_configuration_name: updateVoteData.voting_configuration_name
            };
            dispatch(setCurrentVote(voter));
          }
        }
      });
      return () => vote_subscription.unsubscribe();
    }
  }, [customerId, liveMeeting.votecastAgendaUid, voteState?.voteId]);

  const Page = ({ children }) => {
    return (
      <>
        {(isCloudVotingEnabled && votecast_enableErrorNotification) ? <ErrorNotification /> : ""}
        <div className={votecast_enableErrorNotification ? 'agenda-error-Notification' : ''}>
          <Breadcrumb />
        </div>
        {children}
      </>
    );
  };

  const renderTitle = () => {
    const dateInOffsetTime = getDateInOffsetTime(agenda.starttime);
    const title = `${agenda.name}, ${dateInOffsetTime.format(
      settings.monthAndTimeDisplayFormat
    )}`;
    return (
      <h2 className="agenda-title">
        {title}
        {isCloudVotingEnabled && isMeetingRunning && (
          <img className="padding-left-2" src={CvMeetingLiveIcon} alt='Live Meeting' id='agenda-live-meeting' />
        )}
      </h2>
    );
  };

  const renderAgendaPacketButton = () => {
    if (agenda.council_packet_status_id == 2 || agenda.agenda_packet_status_id == 2) {
      const packetUrl = validatePacketUrl(agenda);
      if (packetUrl) {
        return (
          <a className={classNames("agenda-packet-link", isCloudVotingEnabled ? "agenda-packet-link-cloud-voting" : "")}
            href={packetUrl} target="_blank" aria-label="Agenda Packet Link" rel="noreferrer">
            <Button unstyled={isCloudVotingEnabled} className="agenda-packet-button" size="sm">
              <FontAwesomeIcon
                icon={faFilePdf}
                style={{ marginRight: 4 }}
              />
              Agenda Packet
            </Button>
          </a>
        );
      } else {
        return null;
      }
    }
  }

  const updateMemberJoinedInput = {
    customer_uid: customerId,
    uid: memberId,
    availability_status: AvailabilityStatus.JOINED
  }

  const updateMemberLeftInput = {
    customer_uid: customerId,
    uid: memberId,
    availability_status: AvailabilityStatus.LEFT
  }

  const handleJoinMeetingClick = async () => {
    const getMeetingResponse = await GraphQLAPI.graphql(graphqlOperation(getAgendaListByMeetingGuid, { customer_uid: customerId, meeting_guid: agendaUid }));
    const meetingData = getMeetingResponse?.data?.getVotecastAgendaByMeetingGuid;

    const filteredAndSortedMeetingData = meetingData
      ?.filter(item => item.meeting_status === MeetingStatus.OPEN || item.meeting_status === MeetingStatus.RUNNING)
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const votecastAgendaUid = filteredAndSortedMeetingData?.[0]?.uid ?? null;
    dispatch(setLiveMeeting({ meetingGuid: agendaUid, votecastAgendaUid: votecastAgendaUid }));

    if (filteredAndSortedMeetingData?.length > 0) {
      const updateMemberResponse = await GraphQLAPI.graphql(graphqlOperation(updateMemberAvailabilityStatus, { input: updateMemberJoinedInput }));
      const updateMemberData = updateMemberResponse?.data?.updateVotecastMemberAvailabilityStatus;

      if (updateMemberData && updateMemberData.availability_status === AvailabilityStatus.JOINED) {
        dispatch(setMemberDetails({
          memberId: updateMemberData.uid,
          memberJoinStatus: updateMemberData.availability_status === AvailabilityStatus.JOINED,
          isAttendeePresent: updateMemberData.status && updateMemberData.status.toLowerCase() === MemberAttendance.PRESENT,
          isChair: updateMemberData.chair,
          isVoter: updateMemberData.voting,
        }));
        onJoinMeeting(votecastAgendaUid);
      }
    }
    else {
      // show error notification banner
      setIsMeetingOpenOrRunning(false);
      setIsMeetingRunning(false);
      dispatch(setEnableActiveAgendaErrorNotification(true));
    }
  }

  const handleLeaveMeetingClick = async () => {
    const updateMemberResponse = await GraphQLAPI.graphql(graphqlOperation(updateMemberAvailabilityStatus, { input: updateMemberLeftInput }));
    const updateMemberData = updateMemberResponse?.data?.updateVotecastMemberAvailabilityStatus;

    if (updateMemberData && updateMemberData.availability_status === AvailabilityStatus.LEFT) {
      testOnLeaveMeeting(); //todo: test
      dispatch(setMemberDetails({
        memberId: updateMemberData.uid,
        memberJoinStatus: false,
      }));
    }
  }

  const renderActiveAgenda = () => {
    return (
      <Page>
        <div className="agenda-title-container">
          <div className="agenda-title-side-container"></div>
          <div>{renderTitle()}</div>
          <div className="agenda-title-side-container">
            {isCloudVotingEnabled && (
              isMeetingOpenOrRunning && liveMeeting.memberDetails?.memberId ? (
                liveMeeting.memberDetails.memberJoinStatus ? <LeaveMeetingButton onClick={handleLeaveMeetingClick} /> : <JoinMeetingButton onClick={handleJoinMeetingClick} />
              ) : (
                <JoinMeetingButton onClick={handleJoinMeetingClick} disabled={true} />
              )
            )}
            {!isCloudVotingEnabled && renderAgendaPacketButton()}
          </div>
        </div>
        <div id="agenda-content-container" className="agenda-content-container">
          {isCloudVotingEnabled && renderAgendaPacketButton()}
          <CardGroup className="active-agenda-content">
            <Card gridLayout={{ desktop: { col: 6 } }}>
              <AgendaItems itemsTreeData={treeData} />
            </Card>
            <Card gridLayout={{ desktop: { col: 6 } }} id="agenda-item-detail">
              <Container />
            </Card>
          </CardGroup>
        </div>
      </Page>
    );
  };

  if (isAgendaError || isNotesError || isBookmarksError) {
    return (
      <Page>
        <p>We encountered a problem while loading this agenda</p>
      </Page>
    );
  }

  if (isCurrentUserError /* || isCurrentPersonUidError */ && isCloudVotingEnabled) {
    return (
      <Page>
        <p>Error loading User data. Please try again later.</p>
      </Page>
    );
  }

  if (isLoading) {
    return (
      <Page>
        <div
          className="display-flex flex-column flex-align-center flex-justify-center text-blue"
          style={{ minHeight: 200 }}
        >
          <FontAwesomeIcon
            icon={"spinner"}
            size={"2x"}
            className="fa-spin"
            style={{ marginBottom: 8 }}
          />
          Loading...
        </div>
      </Page>
    );
  } else {
    return renderActiveAgenda();
  }
};

ActiveAgenda.displayName = "Agenda";

export default ActiveAgenda;
