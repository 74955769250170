import GraphQLAPI, { graphqlOperation } from '@aws-amplify/api';

import {
  createAgenda,
  createMembers,
  createCustomer,
  createVotecastAgendaItems,
  createMotionResults,
  createMotionTypes,
  createVotecastSpeakers,
  deleteVotecastAgenda,
  deleteVotecastClip,
  deleteVotecastAgendaItems,
  deleteVotecastMotions,
  deleteVotecastSpeakers,
  deleteMembers,
  deleteVotecastBallots,
  deleteVotecastClips
} from '../../../amplify/graphql/mutations';

import {
  getVotecastClipsByAgendaUid,
  getVotecastAgendaItemsByAgendaUid,
  getVotecastMotionsByAgendaUid,
  getVotecastMembersByAgendaUid,
  getVotecastAgendaById
} from '../../../amplify/graphql/queries';

//Perform individual GraphQL API calls for each table to manipulate data
export const openMeeting = async (inputs) => {
  const {
    agendaInput,
    customerInput,
    agendaItemsArray: agendaItemsInput,
    motionResultInput: motionResultsInput,
    motionTypeInput: motionTypesInput,
    speakersArray: speakersInput,
    memberInput: membersInput,
  } = inputs;

  try {
    const addedAgenda = await GraphQLAPI.graphql(graphqlOperation(createAgenda, { input: agendaInput }));
    const addedCustomer = await GraphQLAPI.graphql(graphqlOperation(createCustomer, { input: customerInput }));
    const addedAgendaItems = await processChunks(agendaItemsInput, createVotecastAgendaItems);
    const addedMotionResults = await processChunks(motionResultsInput, createMotionResults);
    const addedMotionTypes = await processChunks(motionTypesInput, createMotionTypes);
    let addedSpeakers = [];
    if (speakersInput && speakersInput.length > 0) {
      addedSpeakers = await processChunks(speakersInput, createVotecastSpeakers);
    }
    let addedAttendees = [];
    if (membersInput && Object.keys(membersInput).length > 0) {
      addedAttendees = await processChunks(Object.values(membersInput), createMembers);
    }
    return {
      success: true,
      results: {
        addedAgenda,
        addedAttendees,
        addedCustomer,
        addedAgendaItems,
        addedMotionResults,
        addedMotionTypes,
        addedSpeakers,
      },
    };
  } catch (error) {
    console.error("An error occurred in openCloseMeetingActions:", error);
    return {
      success: false,
      error,
    };
  }
};

export const closeMeeting = async (closeMeetingInput) => {
  const {
    deleteAgendaInput,
    deleteClipInput,
    deleteMotionsInput,
    agendaItemsArray: deleteAgendaItemsInput,
    speakersArray: deleteSpeakersInput,
    deleteMembersInput, deleteVotesInput } = closeMeetingInput;

  try {
    let deletedClip;
    const deletedAgenda = await GraphQLAPI.graphql(graphqlOperation(deleteVotecastAgenda, { customer_uid: deleteAgendaInput.customer_uid, uid: deleteAgendaInput.uid }));
    if (deleteClipInput.uid) {
      deletedClip = await GraphQLAPI.graphql(graphqlOperation(deleteVotecastClip, { agenda_uid: deleteClipInput.agenda_uid, uid: deleteClipInput.uid }));
    }
    const deletedAgendaItems = await processChunks(deleteAgendaItemsInput, deleteVotecastAgendaItems);
    let deletedMotions = [];
    if (deleteMotionsInput && deleteMotionsInput.length > 0) {
      deletedMotions = await processChunks(Object.values(deleteMotionsInput), deleteVotecastMotions);
    }
    let deletedVotes = [];
    if (deleteVotesInput && deleteVotesInput.length > 0) {
      deletedVotes = await processChunks(Object.values(deleteVotesInput), deleteVotecastBallots);
    }
    let deletedSpeakers = [];
    if (deleteSpeakersInput && deleteSpeakersInput.length > 0) {
      deletedSpeakers = await processChunks(deleteSpeakersInput, deleteVotecastSpeakers);
    }
    let deletedAttendees = [];
    if (deleteMembersInput && Object.keys(deleteMembersInput).length > 0) {
      deletedAttendees = await processChunks(Object.values(deleteMembersInput), deleteMembers);
    }

    return {
      success: true,
      results: {
        deletedAgenda,
        deletedAttendees,
        deletedClip,
        deletedAgendaItems,
        deletedMotions,
        deletedVotes,
        deletedSpeakers,
      },
    };
  } catch (error) {
    console.error("An error occurred while deleting the data from dynamoDB:", error);
    return {
      success: false,
      error,
    };
  }
}

const fetchAndMapData = async (operation, queryPayload) => {
  const response = await GraphQLAPI.graphql(graphqlOperation(operation, queryPayload));
  const key = Object.keys(response.data)[0];
  return response?.data?.[key].map(item => ({
    uid: item.uid,
    ...(operation === getVotecastMembersByAgendaUid ? { customer_uid: item.customer_uid } : { agenda_uid: item.agenda_uid })
  }));
};

export const deleteStaleData = async (inputs) => {
  try {
    const queryPayload = {
      customer_uid: inputs?.agendaInput?.customer_uid, 
      agenda_uid: inputs?.agendaInput?.uid
    };

    const [agendaItemsToBeDeleted, clipsToBeDeleted, motionsToBeDeleted, membersToBeDeleted] = await Promise.all([
      fetchAndMapData(getVotecastAgendaItemsByAgendaUid, queryPayload),
      fetchAndMapData(getVotecastClipsByAgendaUid, queryPayload),
      fetchAndMapData(getVotecastMotionsByAgendaUid, queryPayload),
      fetchAndMapData(getVotecastMembersByAgendaUid, queryPayload)
    ]);

  
    const deletedAgenda = await GraphQLAPI.graphql(graphqlOperation(deleteVotecastAgenda, {customer_uid: inputs?.agendaInput?.customer_uid, uid: inputs?.agendaInput?.uid}));
    let deletedAgendaItems = [];
    if (agendaItemsToBeDeleted && agendaItemsToBeDeleted.length > 0) {
      deletedAgendaItems = await processChunks(Object.values(agendaItemsToBeDeleted), deleteVotecastAgendaItems);
    }
    let deletedClips = [];
    if (clipsToBeDeleted && clipsToBeDeleted.length > 0) {
      deletedClips = await processChunks(Object.values(clipsToBeDeleted), deleteVotecastClips);
    }
    let deletedMotions = [];
    if (motionsToBeDeleted && motionsToBeDeleted.length > 0) {
      deletedMotions = await processChunks(Object.values(motionsToBeDeleted), deleteVotecastMotions);
    }
    let deletedMembers = [];
    if (membersToBeDeleted && membersToBeDeleted.length > 0) {
      deletedMembers = await processChunks(Object.values(membersToBeDeleted), deleteMembers);
    }
    
    return {
      success: true,
      results: {
        deletedAgenda,
        deletedAgendaItems,
        deletedClips,
        deletedMotions,
        deletedMembers
      }
    };
  } catch (error) {
    console.error("An error occurred while deleting stale data from dynamoDB:", error);
    return {
      success: false,
      error,
    };
  }
}

export async function processChunks(input, operation) {
  //Passing the graphQL method through this helper method will auto handle batch operation limits by AWS
  const chunkSize = 25; // Adjust based on operation limits, AWS can perform batch operation up to 25 items only per request
  const chunks = chunkArray(input, chunkSize);
  let results = [];
  for (const chunk of chunks) {
    try {
      const result = await GraphQLAPI.graphql(graphqlOperation(operation, { input: chunk }));
      results.push(result);
    } catch (error) {
      console.error('Error processing graphql batch:', error);
    }
  }
  return results;
}

function chunkArray(array, size) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}