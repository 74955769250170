import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetVideoQuery } from '../../services/videoApi';
import { Accordion } from '@trussworks/react-uswds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ArchivePlayer from './ArchivePlayer';
import Timestamps from './Timestamps';
import { getDateInOffsetTime } from '../../helpers/dayjsHelpers';
import settings from '../../common/settings';
import Breadcrumb from "../Navigation/Breadcrumb";

const ActiveVideo = () => {
  const { video_id: videoId } = useParams();

  const { data, isLoading, isSuccess, isError } = useGetVideoQuery(videoId);

  const renderTitle = () => {
    if (isSuccess) {
      const { name, date } = data?.video;
      const title = `${name}, ${getDateInOffsetTime(date).format(settings.monthAndTimeDisplayFormat)}`;
      return <h2 className='video-title'>{title}</h2>;
    }
  };

  const renderPlayer = () => {
    if (!isSuccess) {
      return null;
    }

    const { src, captionsurl, timestamps } = data?.video;
    return <ArchivePlayer src={src || ''} captionsUrl={captionsurl || ''} timestamps={timestamps || []}/>;
  };

  const renderTimestamps = () => {
    if (!isSuccess) {
      return null;
    }

    const { timestamps } = data?.video;

    const items = [
      {
        title: `Timestamps (${timestamps?.length})`,
        content: (
          <Timestamps items={timestamps || []}/>
        ),
        expanded: true,
        id: 'video-timestamps',
        headingLevel: 'h3',
      },
    ];

    return (
      <div id="video-timestamp-container">
        <Accordion bordered={true} items={items}/>
      </div>
    );
  };

  const renderContent = () => {
    if (isError) {
      return (
        <p className='text-center text-red'>
          We encountered a problem while loading video.
          <br/>
          Please try again later.
        </p>
      );
    }
    if (isLoading) {
      return (
        <div
          className='display-flex flex-column flex-align-center flex-justify-center text-blue'
          style={{ minHeight: 200 }}
        >
          <FontAwesomeIcon
            icon={'spinner'}
            size={'2x'}
            className='fa-spin'
            style={{ marginBottom: 8 }}
          />
          Loading...
        </div>
      );
    }

    return (
      <>
        {renderTitle()}
        {renderPlayer()}
        {renderTimestamps()}
      </>
    );
  };

  return (
    <div id='video-detail-container'>
      <Breadcrumb/>
      {renderContent()}
    </div>
  );
}

ActiveVideo.displayName = 'Video';

export default ActiveVideo;