import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentTitle } from '../common/currentPageSlice';
import { useEffect } from 'react';

// eslint-disable-next-line react/prop-types
export default function GuardedRoute({ children, ...rest }) {
  let dispatch = useDispatch();
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  let { title } = { ...rest };
  useEffect(() => {
    dispatch(setCurrentTitle(title));
  },[dispatch,title]);

  return (
    <Route {...rest} render={(props) => (
      isLoggedIn ? children : <Redirect to="/login" />
    )} />
  )
}

GuardedRoute.displayName = 'Guarded Route'
