import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLiveMeeting, selectMotionState, selectVoteState,setIsVotingStarted,
  selectIsVotingStarted, setMotionDetails
} from '../../common/liveMeetingSlice';
import { Button } from '@trussworks/react-uswds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Popover } from 'antd';
import { toast } from 'react-toastify';
import VotingModal from './Voting/VotingModal';
import { v4 as uuidv4 } from "uuid";
import GraphQLAPI, { graphqlOperation } from '@aws-amplify/api';
import { createVotecastMotion, updateVotecastMotion } from '../../../../amplify/graphql/mutations';
import { VotecastRequestOrigin, AgendaItemType } from '../../../constants/cloudVotingConstants';

const toastConfig = {
  className: "live-meeting-toast",
  position: "bottom-left",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
  closeButton: false
};

export default function Motion() {
  const dispatch = useDispatch();

  const motionState = useSelector(selectMotionState);
  const liveMeeting = useSelector(selectLiveMeeting);
  const votecastAgendaUid = liveMeeting.votecastAgendaUid;
  const customerId = useSelector(state => state.auth.customer_uid);
  const votecast_isVotingStarted = useSelector(selectIsVotingStarted);
  const currentItemHypatiaId = liveMeeting.currentItemHypatiaId;


  const createOrUpdateMotionInput = {
    uid: !motionState.motionId ? uuidv4() : motionState.motionId,
    customer_uid: customerId,
    agenda_uid: votecastAgendaUid,
    origin_uid: '',
    position: !motionState.position ? 1 : (motionState.position + 1),
    motion_type_name: '',
    motion_result_name: '',
    parent_agenda_item_uid: liveMeeting.motionDetails.motionItemId,
    motion_passage_rule_name: '',
    motion_action_name: liveMeeting.motionDetails.motionTo ? liveMeeting.motionDetails.motionTo : '',
    request_origin: VotecastRequestOrigin.ATTENDEE_UPDATE
  };

  const setMover = async () => {
    createOrUpdateMotionInput.motioner_uid = liveMeeting.memberDetails.memberId;
    createOrUpdateMotionInput.motioner_name = motionState.username;

    let setMoverResponse;
    let moverData = {};
    if (motionState.motionId) {
      setMoverResponse = await GraphQLAPI.graphql(graphqlOperation(updateVotecastMotion, { input: createOrUpdateMotionInput }));
      moverData = setMoverResponse?.data?.updateVotecastMotion;
    } else {
      createOrUpdateMotionInput.request_origin = VotecastRequestOrigin.ATTENDEE_CREATE;
      setMoverResponse = await GraphQLAPI.graphql(graphqlOperation(createVotecastMotion, { input: createOrUpdateMotionInput }));
      moverData = setMoverResponse?.data?.createVotecastMotion;
    }

    if (moverData && moverData.uid && moverData.motioner_name) {
      toast("You have Motioned", toastConfig);
    }
  }

  const setSeconder = async () => {
    createOrUpdateMotionInput.seconder_uid = liveMeeting.memberDetails.memberId;
    createOrUpdateMotionInput.seconder_name = motionState.username;
    const setSeconderResponse = await GraphQLAPI.graphql(graphqlOperation(updateVotecastMotion, { input: createOrUpdateMotionInput }));
    const seconderData = setSeconderResponse?.data?.updateVotecastMotion;

    if (seconderData && seconderData.seconder_name) {
      toast("You have Seconded", toastConfig);
    }
  }

  const withdrawMover = async () => {
    createOrUpdateMotionInput.motioner_uid = '';
    createOrUpdateMotionInput.motioner_name = '';
    createOrUpdateMotionInput.seconder_uid = '';
    createOrUpdateMotionInput.seconder_name = '';
    await GraphQLAPI.graphql(graphqlOperation(updateVotecastMotion, { input: createOrUpdateMotionInput }));
  }

  const withdrawSeconder = async () => {
    createOrUpdateMotionInput.seconder_uid = '';
    createOrUpdateMotionInput.seconder_name = '';
    await GraphQLAPI.graphql(graphqlOperation(updateVotecastMotion, { input: createOrUpdateMotionInput }));
  }

  const resetMotion = () => {
    withdrawMover();
    dispatch(setMotionDetails({
      isMotionEnabled: false,
      motionTo: '',
      motionMover: '',
      motionSeconder: ''
    }));
  }

  const callVotingModal = () => {
    dispatch(setIsVotingStarted(true));
  }

  const motionTo = (
    <div className="display-flex margin-top-2">
      <div className="flex-1">
        <div className="sub-title">Motion To</div>
        <div id="motion-to" className='sub-text'>
          {motionState.motionTo
            ? motionState.motionTo
            : 'Describing Motion'}
        </div>
      </div>

    </div>
  );

  const messageLabel = (message) => (
    <div className='motion-message'>
      <FontAwesomeIcon icon={['far', 'check-circle']} color='#4CAF50' className='margin-right-1' />
      <span>{message}</span>
    </div>
  );

  const motionButton = (
    <Button type="button" disabled={!motionState.userCanMotion} size='small' className="motion-button padding-y-1"
      onClick={setMover} title="Click to motion"
    >
      Motion
    </Button>
  );

  const withdrawMotionButton = (
    <Button type="button" size='small' className="withdraw-motion-button" unstyled
      onClick={withdrawMover} title="Click to withdraw motion"
    >
      <FontAwesomeIcon icon={'times'} size={'lg'} className="margin-right-1" />
      <span>Withdraw Motion</span>
    </Button>
  );

  const mover = (
    <div className={classNames("motion-mover-container display-flex margin-top-2", { "motion-submitted": motionState.isUserSubmittedMotion })}>
      <div className="flex-1">
        <div className="sub-title">Mover</div>
        <div id="motion-mover" className='sub-text'>
          {motionState.mover
            ? (motionState.isUserSubmittedMotion ? messageLabel("You have Motioned") : motionState.mover)
            : 'Name of Mover'}
        </div>
      </div>
      <div className="flex-auto">
        {motionState.isUserSubmittedMotion ? withdrawMotionButton : motionButton}
      </div>
    </div>
  );

  const seconderButton = (
    <Button type="button" disabled={!motionState.userCanSecond} size='small' className="motion-button padding-y-1"
      onClick={setSeconder} title="Click to second"
    >
      Second Motion
    </Button>
  );

  const withdrawSeconderButton = (
    <Button type="button" size='small' className="withdraw-motion-button" unstyled
      onClick={withdrawSeconder} title="Click to withdraw seconder"
    >
      <FontAwesomeIcon icon={'times'} size={'lg'} className="margin-right-1" />
      <span>Withdraw Second</span>
    </Button>
  );

  const seconder = (
    <div className={classNames("motion-mover-container display-flex margin-top-2", { "motion-submitted": motionState.isUserSubmittedSecond })}>
      <div className="flex-1">
        <div className="sub-title">Seconder</div>
        <div id="motion-seconder" className='sub-text'>
          {motionState.seconder
            ? (motionState.isUserSubmittedSecond ? messageLabel("You have Seconded") : motionState.seconder)
            : 'Name of Seconder'}
        </div>
      </div>
      <div className="flex-auto">
        {motionState.isUserSubmittedSecond ? withdrawSeconderButton : seconderButton}
      </div>
    </div>
  );

  const previousMotionTitle = (
    <span className='previous-motion-title'>
      Previous Motion Summary
    </span>
  );

  const previousMotion = () => {
    const isEmpty = !motionState.previousMotion;
    if (isEmpty) {
      return (
        <div>
          <div className='previous-motion-sub-title'>No summary available</div>
          <div className='previous-motion-sub-text'>A summary will appear once a <br /> motion is complete</div>
        </div>
      )
    }

    const { motionTo, mover, seconder } = motionState.previousMotion;
    return (
      <div className='padding-1'>
        <div className='display-flex flex-column'>
          <div className='previous-motion-sub-title'>Motion to</div>
          <div className='previous-motion-sub-text'>{motionTo ?? "Describing Motion"}</div>
        </div>
        <div className='display-flex flex-column margin-top-2'>
          <div className='previous-motion-sub-title'>Mover</div>
          <div className='previous-motion-sub-text'>{mover ?? "Name of Mover"}</div>
        </div>
        <div className='display-flex flex-column margin-top-2'>
          <div className='previous-motion-sub-title'>Seconder</div>
          <div className='previous-motion-sub-text'>{seconder ?? "Name of Seconder"}</div>
        </div>
      </div>
    )

  };

  return (
    <div className="motion-container padding-0 margin-top-2 margin-bottom-2">
      <div className="display-flex">
        <div className="flex-1">
          <span className="text-base-darkest text-bold font-sans-xs">Motion on the Floor</span>
        </div>

        <div className="flex-auto">
          <Popover
            content={previousMotion()}
            title={previousMotionTitle} prefixCls="legislate-popover"
            placement='bottomRight'>
            <span style={{ color: "#0058AA", fontSize: "14px" }}>Motion Summary</span>
          </Popover>
        </div>
      </div>
      {motionTo}
      {mover}
      {seconder}
      {liveMeeting.memberDetails?.isAttendeePresent && liveMeeting.memberDetails?.isChair && (
        <div className="buttons-container">
          <Button
            type="button"
            className={'reset-motion-text'}
            unstyled
            onClick={resetMotion}
            disabled={!liveMeeting.motionDetails.isMotionEnabled || !motionState.mover || votecast_isVotingStarted}
            style={{
              color: !liveMeeting.motionDetails.isMotionEnabled || !motionState.mover || votecast_isVotingStarted ? '#B3B7BA' : undefined,
              background: !liveMeeting.motionDetails.isMotionEnabled || !motionState.mover || votecast_isVotingStarted ? 'white' : undefined
            }}
          >
            <FontAwesomeIcon
              icon={'redo'}
              size={'lg'}
              style={{ marginRight: 6 }}
              className='reset-motion-text-icon'

            />
            <span>Reset Motion</span>
          </Button>

          <Button
            type="button"
            className={'start-voting-text'}
            unstyled
            onClick={callVotingModal}
            disabled={!votecast_isVotingStarted}
            style={{
              color: !votecast_isVotingStarted ? '#B3B7BA' : undefined,
              background: !votecast_isVotingStarted ? 'white' : undefined
            }}
          >
            <FontAwesomeIcon
              icon={'vote-yea'}
              size={'lg'}
              style={{ marginRight: 6 }}
              className='start-voting-text-icon'
            />
            <span>Start Voting</span>
          </Button>
        </div>
      )}
      {liveMeeting.isVotingStarted && <VotingModal />}
    </div>

  );
}

Motion.displayName = 'Motion on the Floor'