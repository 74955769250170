import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: ''
};

const currentPage = createSlice({
  name: 'currentPage',
  initialState,
  reducers: {
    setCurrentTitle: (state, { payload }) => {
      state.title = payload;
    },
  },
});

export const { setCurrentTitle } = currentPage.actions;

export default currentPage.reducer;
