import GranicusLogoBlack from './granicus_logo_black.png';
import GranicusLogoMixed from './granicus_logo_mixed.png';
import LegislateLogoSmallWhite from './legislate_logo_small_white.png';
import CvMeetingLiveIcon from './cv_meeting_live_icon.png';

export {
  GranicusLogoBlack,
  GranicusLogoMixed,
  LegislateLogoSmallWhite,
  CvMeetingLiveIcon
};
