import GraphQLAPI, { graphqlOperation } from '@aws-amplify/api';

export const invokeGraphqlOperationWithNextToken = async (query, variables) => {
    let allData = [];
    let nextToken = null;

    do {
        const response = await GraphQLAPI.graphql(graphqlOperation(query, { ...variables, nextToken }));
        const key = Object.keys(response.data)[0];
        const data = response?.data?.[key];
        allData = [...allData, ...data.items];
        nextToken = data.nextToken;
    } while(nextToken);

    return allData;
}

export const invokeGraphqlOperation = async (query, variables) => {
    const response = await GraphQLAPI.graphql(graphqlOperation(query, variables));
    const key = Object.keys(response.data)[0];
    const data = response?.data?.[key];
    return data;
}