import React from 'react';
import { Table } from '@trussworks/react-uswds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStickyNote as faStickyNoteOutline
} from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';

export default function NoteTable(props) {
  const { items, handleClickRow } = props;

  const handleKeyPress = (e, uid) => {
    e.preventDefault();
    if (e.key === 'Enter') {
      handleClickRow(uid);
    }
  };

  const renderRow = (rowData, index) => {
    return (
      <tr className='notes-row'
        key={ index }
        tabIndex={ 0 } // row can be selected and is in te default tab order.
        onClick={ () => handleClickRow(rowData.uid) }
        onKeyPress={ (e) => handleKeyPress(e, rowData.uid) }
      >
        <td className='note-icon-column'>
          <FontAwesomeIcon
            icon={ faStickyNoteOutline }
            color='#005EA2'
          />
        </td>
        <td className='note-item-name-column' scope='row'>
          <p className='truncate-text'>
            { rowData.title }
          </p>
        </td>
        <td className='note-column'>
          <p className='truncate-text'>
            { rowData.note }
          </p>
        </td>
      </tr>
    );
  };

  return (
    <Table fullWidth compact bordered={ false }>
      <colgroup>
        <col className='note-icon-column'/>
        <col className='note-item-name-column'/>
        <col className='note-column'/>
      </colgroup>
      <thead className='sr-only'>
        <tr>
          <th scope='col'>icon</th>
          <th scope='col'>meeting item</th>
          <th scope='col'>beginning of note</th>
        </tr>
      </thead>
      <tbody>{ items.map((rowData, index) => renderRow(rowData, index)) }</tbody>
    </Table>
  );
}

NoteTable.displayName = 'Note Table';

NoteTable.propTypes = {
  items: PropTypes.array.isRequired,
  handleClickRow: PropTypes.func.isRequired,
};

