import React from 'react';
import { Table } from '@trussworks/react-uswds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc.js";
dayjs.extend(utc);

export default function Timestamps(props) {
  const { items } = props;

  const renderRow = (rowData, index) => {
    return (
      <tr
        key={index}
        tabIndex={index + 1}
        id={rowData.entrytime}
      >
        <td scope='row'>
          <p className='truncate-text'>
            <FontAwesomeIcon
              icon={'play'}
              color='#005EA2'
              style={{ marginRight: 20 }}
            />
            {rowData.name}
          </p>
        </td>
        <td scope='row' style={{ textAlign: 'center' }}>
          {dayjs.utc(rowData.entrytime * 1000).format('HH:mm:ss')}
        </td>
      </tr>
    );
  };

  return (
    <Table fullWidth compact bordered={false}>
      <thead className='sr-only'>
        <tr>
          <th scope='col'>name</th>
          <th scope='col'>time</th>
        </tr>
      </thead>
      <tbody>{items.map((rowData, index) => renderRow(rowData, index))}</tbody>
    </Table>
  );
}

Timestamps.displayName = 'Index Table';

Timestamps.propTypes = {
  items: PropTypes.array.isRequired
};
