import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectItemKeys,
  selectSelectedItemKey,
  setSelectedItemKey,
} from './activeAgendaSlice';
import { Tree } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { selectLiveMeeting } from '../../common/liveMeetingSlice';

const AgendaItems = (props) => {
  const dispatch = useDispatch();
  const selectedItemKey = useSelector(selectSelectedItemKey);
  const itemKeys = useSelector(selectItemKeys);
  const liveMeeting = useSelector(selectLiveMeeting);
  const isCloudVotingEnabled = useSelector(state => state.settingsState.CloudVotingEnable);

  const showItemDetail = (itemKey) => {
    dispatch(setSelectedItemKey(itemKey));
  };

  React.useEffect(() => {
    if (typeof(selectedItemKey) !== 'undefined' && selectedItemKey.length > 0) {
      const topPosition = document.getElementsByClassName('ant-tree-treenode-selected')[0].offsetTop
      document.getElementById('agenda-items-container').scrollTop = topPosition - 10
    }
  }, [props]);

  const renderTreeTitle = (node) => (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      {node.title}
      { isCloudVotingEnabled && liveMeeting.memberDetails?.memberJoinStatus && node.key === liveMeeting.currentItemGuid &&
        <span style={{ marginLeft: '3px', fontSize: '0.9em', display: 'flex', alignItems: 'center', color: 'green' }}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="sm"
            style={{ color: 'green', marginRight: '4px' }}
          />
          Active
        </span>
      }
    </span>
  );

  return (
    <div id="agenda-items-container">
      {props?.itemsTreeData.length > 0 ?
        (<Tree
          className="agenda-items"
          defaultExpandAll={true}
          selectedKeys={[selectedItemKey]}
          expandedKeys={itemKeys}
          treeData={props.itemsTreeData}
          showIcon={false}
          onSelect={(_, e) => showItemDetail(e.node.key)}
          switcherIcon={null}
          titleRender={renderTreeTitle}
        />) :
        (<p className="active-agenda-empty-message" data-testid="empty_agenda_message">
        Items were not found for this meeting agenda
        </p>)
      }

    </div>
  );
};

AgendaItems.displayName = 'AgendaItems';

AgendaItems.propTypes = {
  itemsTreeData: PropTypes.array.isRequired,
};

export default AgendaItems;
