import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import settings from '../../../common/settings';
import { getDateInOffsetTime } from '../../../helpers/dayjsHelpers';
import { Button } from '@trussworks/react-uswds';
import Truncate from '../../Common/Truncate';
import { ExpandCountContext } from './CommentModal';

const Comment = (props) => {
  const { comment, displaySentiment, defaultExpanded } = props;
  const [expanded, setExpanded] = useState(false);
  const [truncated, setTruncated] = useState(false);
  const [truncateable, setTruncateable] = useState(false);

  const count = useContext(ExpandCountContext);

  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded, count]);

  const commentTime = getDateInOffsetTime(comment.created_at).format(
    settings.monthAndTimeDisplayFormat
  );

  const renderSentiment = () => {
    return (
      <span
        data-status={comment.position ? comment.position : 'None'}
        className={'comment-sentiment'}
        aria-label={'Comment sentiment'}
        data-testid={comment.user.name + `-comment-sentiment`}
      />
    );
  };

  const handleTruncate = (t) => {
    if (truncated !== t) setTruncated(t);
  };

  const handleIsTruncateable = (t) => {
    if (truncateable !== t) setTruncateable(t);
  };

  const toggleLines = () => {
    setExpanded(!expanded);
  };

  const render = () => {
    return (
      <li className={'comment'} aria-label={'Comment'}>
        <span className={'comment-name'} aria-label={'Commenter name'}>
          {comment.user.name}
        </span>
        {displaySentiment && renderSentiment()}
        <div className={'comment-body'} aria-label={'Comment body'}>
          <Truncate
            lines={3}
            disable={expanded}
            ellipsis={
              <span>
                ... <br />
                <Button
                  type='button'
                  unstyled
                  value={'View More'}
                  onClick={toggleLines}
                >
                  View More
                </Button>
              </span>
            }
            onTruncate={handleTruncate}
            onIsTruncateable={handleIsTruncateable}
          >
            {comment.body}
          </Truncate>
          <div>
            {truncateable && !truncated && expanded && (
              <Button
                type='button'
                unstyled
                value={'View Less'}
                onClick={toggleLines}
              >
                View Less
              </Button>
            )}
          </div>
        </div>
        <div className={'comment-secondary-text'} aria-label={'Comment time'}>
          {commentTime}
        </div>
      </li>
    );
  };

  return <>{render()}</>;
};

Comment.displayName = 'Comment';

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  displaySentiment: PropTypes.bool.isRequired,
  defaultExpanded: PropTypes.bool,
};

Comment.defaultProps = {
  defaultExpanded: false,
};

export default Comment;
