import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useGetAgendasQuery } from '../../services/agendaApi';
import { selectExpanded } from '../Navigation/navigationSlice';
import { selectLastSyncedAgendas } from '../../common/syncStatusSlice';
import { selectSelectedMonth, setSelectedMonth } from './agendaSlice';
import IndexTable from '../Common/IndexTable';
import PaginationByMonth from '../Common/PaginationByMonth';
import classNames from 'classnames';
import settings from '../../common/settings';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { getDateInOffsetTime } from '../../helpers/dayjsHelpers';
import { selectIsCloudVotingEnabled } from "../../common/settingSlice";

const getAgendaTableData = (agendas) =>
  agendas.map((agenda) => {
    const dateInOffsetTime = getDateInOffsetTime(agenda.starttime);
    return {
      id: agenda.id,
      name: agenda.name,
      date: dateInOffsetTime.format(settings.monthDisplayFormat),
      time: dateInOffsetTime.format(settings.timeDisplayFormat),
      uid: agenda.uid,
      status: agenda.status,
    };
  });

const agendaIndexTableHeadings = ['Meeting Name', 'Date', 'Time'];

export default function AgendaIndex() {
  let history = useHistory();
  const dispatch = useDispatch();

  /*
    keep this here as an example of polling, onReconnect, onFocus
    const { isLoading, isFetching, isSuccess, refetch } = useGetAgendasQuery(
      undefined,
      {
        pollingInterval: 3000,
        refetchOnReconnect: false,
        refetchOnFocus: true,
      }
    );
    */

  const selectedMonth = useSelector(selectSelectedMonth);
  const startTime = dayjs(selectedMonth, settings.paginationDateFormat)
    .startOf('month')
    .format(settings.memaStartTimeUrlParamFormat);
  const endTime = dayjs(selectedMonth, settings.paginationDateFormat)
    .endOf('month')
    .format(settings.memaEndTimeUrlParamFormat);

  const {
    data: { events: agendas = [] } = {},
    isLoading,
    isFetching,
    isSuccess,
    refetch,
  } = useGetAgendasQuery({ startTime, endTime });

  const updatedTime = useSelector(selectLastSyncedAgendas);
  const expanded = useSelector(selectExpanded);

  const dataStatus = isLoading
    ? 'initializing'
    : isFetching
      ? 'loading'
      : isSuccess
        ? 'loaded'
        : 'error';

  useEffect(() => {
    return () => {
      // reset seletedMonth while leaving the agenda index page
      dispatch(setSelectedMonth(undefined));
    };
  }, [dispatch]);

  // Table headings need to be in the same order as table data for correct display order,
  // UID will will not be displayed but is needed for onClick, onKeyPress actions

  const handleClickRow = (agendaId) => {
    // Find the GUID for the given agenda ID to avoid forcing the IndexTable to pass GUIDs instead of IDs
    const agenda = agendas.find((agenda) => agenda.id === agendaId);
    history.push({
      pathname: `/agendas/${agenda.uid}`,
      state: {
        month: selectedMonth,
        prevPath: "/agendas",
      }
    });
  };

  const pageChangeHandler = (month) => {
    dispatch(setSelectedMonth(month));
  };

  const isCloudVotingEnabled = useSelector(selectIsCloudVotingEnabled);

  return (
    <div id={'agendas-container'}>
      <div className="table-content agenda-content margin-bottom-8">
        <IndexTable
          dataType={'meetings'}
          captionText={'Select a meeting to view the agenda.'}
          emptyDataMsg={'There are no meetings for the selected month.'}
          tableHeadings={agendaIndexTableHeadings}
          tableData={getAgendaTableData(agendas)}
          dataStatus={dataStatus}
          handleClickRow={handleClickRow}
          reload={refetch}
          loadedDataTime={updatedTime}
          cloudVotingEnabled={isCloudVotingEnabled}
        />
      </div>
      {isSuccess && selectedMonth && (
        <div
          className={classNames('pagination', 'agendas-pagination', {
            expanded: !expanded,
          })}
        >
          <PaginationByMonth
            selected={selectedMonth}
            onChange={pageChangeHandler}
            previousOffsetMonths={0}
            nextOffsetMonths={0}
          />
        </div>
      )}
    </div>
  );
}

AgendaIndex.displayName = 'Agenda Index';
