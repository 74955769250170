import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faAngleRight,
  faAngleLeft,
  faList,
  faStickyNote,
  faBookmark,
  faPlay,
  faSignOutAlt,
  faRedo,
  faRedoAlt,
  faSpinner,
  faUndoAlt,
  faVideo,
  faFilePdf,
  faCheckCircle,
  faExclamationCircle,
  faTimes,
  faMicrophone,
  faUser,
} from '@fortawesome/free-solid-svg-icons'; // icon={['fas', <icon_name>]}

import {
  faComment,
  faBookmark as faBookmarkOutline,
  faStickyNote as faStickyNoteOutline,
} from '@fortawesome/free-regular-svg-icons';

export default function registerIconLibrary() {
  library.add(
    faAngleRight,
    faAngleLeft,
    faList,
    faStickyNote,
    faBookmark,
    faPlay,
    faSignOutAlt,
    faRedo,
    faRedoAlt,
    faSpinner,
    faUndoAlt,
    faVideo,
    faComment,
    faBookmarkOutline,
    faStickyNoteOutline,
    faFilePdf,
    faCheckCircle,
    faExclamationCircle,
    faTimes,
    faMicrophone,
    faUser
  );
}
