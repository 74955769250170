import React from 'react';
import classnames from 'classnames'
import PropTypes from 'prop-types';
import { Button } from '@trussworks/react-uswds';

const JoinMeetingButton = ({ onClick, disabled, isLoading, className }) => {

  return (
    <Button type="button" size='small'
      disabled={disabled}
      className={classnames('btn-join-meeting text-no-wrap', className)}
      onClick={(isLoading || disabled) ? undefined : onClick}>
      {<span>Join Meeting</span>}
    </Button>
  )
}

export default JoinMeetingButton;

JoinMeetingButton.displayName = 'Joining Meeting Button';

JoinMeetingButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string
};
