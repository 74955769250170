import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Common/Modal';
import CommentModalHeader from './CommentModalHeader';
import CommentModalBody from './CommentModalBody';
import Delayed from '../../Common/Delayed';

export const ExpandCountContext = React.createContext();

const CommentModal = (props) => {
  const { isOpen, onClose, comments } = props;
  const [expanded, setExpanded] = useState(false);
  const [count, setCount] = useState(0);

  const handleClose = () => {
    onClose();
  };

  const handleExpanded = (expand) => {
    setExpanded(expand);
    setCount(count + 1);
  };

  let sentimentPercentages;
  let displaySentiment = false;

  if (comments.length) {
    sentimentPercentages = {
      support: Math.floor(
        (comments.filter((comment) => comment.position === 'Support').length *
          100) /
          comments.length
      ),
      oppose: Math.floor(
        (comments.filter((comment) => comment.position === 'Oppose').length *
          100) /
          comments.length
      ),
      neutral: Math.floor(
        (comments.filter((comment) => comment.position === 'Neutral').length *
          100) /
          comments.length
      ),
      noResponse: Math.floor(
        (comments.filter((comment) => comment.position === null).length * 100) /
          comments.length
      ),
    };
    displaySentiment = sentimentPercentages.noResponse !== 100;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      closeTimeoutMS={0}
      headerContent={
        <CommentModalHeader
          comments={comments}
          displaySentiment={displaySentiment}
          sentimentPercentages={sentimentPercentages}
          onExpand={handleExpanded}
        />
      }
      bodyContent={
        <ExpandCountContext.Provider value={count}>
          <Delayed waitBeforeShow={10}>
            <CommentModalBody
              comments={comments}
              displaySentiment={displaySentiment}
              expanded={expanded}
            />
          </Delayed>
        </ExpandCountContext.Provider>
      }
      contentLabel='Comments'
      contentClassName='comment-modal'
    ></Modal>
  );
};

CommentModal.displayName = 'CommentModal';

CommentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  comments: PropTypes.array.isRequired,
};

CommentModal.defaultProps = {
  isOpen: false,
};

export default CommentModal;
