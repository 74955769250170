import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Label,
  TextInput,
  Button,
  Fieldset,
  Alert,
} from '@trussworks/react-uswds';
import {
  GranicusLogoMixed,
  LegislateLogoSmallWhite,
} from '../../../../assets/images/legislate/imageIndex';
import { useResetPasswordMutation } from '../../services/authApi';

// respose status.
const FormStatus = {
  None: '',
  Success: 'SUCCESS',
  ErrorLocal: 'ERROR-LOCAL',
  ErrorServer: 'ERROR-SERVER',
};

export default function PasswordResetForm() {
  const [message, setMessage] = useState(FormStatus.None);
  const [username, setUsername] = useState('');

  const errorRef = useRef(null);
  const successRef = useRef(null);
  const errorBannerRef = useRef(null);

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  useEffect(() => {
    if (message === FormStatus.Success) {
      successRef.current.focus();
    } else if (message === FormStatus.ErrorLocal) {
      errorRef.current.focus();
    } else if (message === FormStatus.ErrorServer) {
      errorBannerRef.current.focus();
    }
  }, [message]);

  const submit = async(e) => {
    e.preventDefault();
    if (username === '') {
      setMessage(FormStatus.ErrorLocal);
    } else {
      setMessage(FormStatus.None);
      await requestReset(username);
    }
  };

  async function requestReset(username) {
    try {
      await resetPassword(username).unwrap();
      setMessage(FormStatus.Success);
    } catch (err) {
      setMessage(FormStatus.ErrorServer);
    }
  }

  const closeErrorBannerHandle = () => {
    setMessage(FormStatus.None);
  };

  const errorBanner = message === FormStatus.ErrorServer && (
    <div ref={errorBannerRef} tabIndex={0}>
      <Alert
        type='error'
        heading='Error'
        className='passwordReset-error-banner'
        cta={
          <Button
            type='button'
            role='button'
            data-testid='close-error-banner-button'
            outline
            onClick={closeErrorBannerHandle}
          >
            Close
          </Button>
        }
      >
        Network error - this operation cannot be completed at this time. For
        further assistance please contact Granicus customer support 1(800)
        314-0147.
      </Alert>
    </div>
  );

  return (
    <>
      {errorBanner}
      <div className='passwordReset'>
        <div className='legislate-scroll-bar'>
          <div className='login-flexColumn'>
            <div className='login-LegislateLogo'>
              <img src={LegislateLogoSmallWhite} alt='' />
              <h1 aria-label='Legislate by Granicus'>Legislate</h1>
            </div>

            <Form
              onSubmit={submit}
              className='passwordReset-form'
              data-testid='reset'
            >
              <Fieldset
                legend='Forgot password'
                legendStyle='large'
                className='passwordReset-fieldset'
              >
                {message === FormStatus.Success && (
                  <div ref={successRef} tabIndex={0}>
                    <Alert
                      noIcon
                      className='passwordReset-alert'
                      type='success'
                    >
                      Recovery email sent. Please check your email and follow
                      the instructions to reset your account password. If you do
                      not receive an email then please check your spam filter
                      settings and/or verify that you entered the correct
                      username.
                    </Alert>
                  </div>
                )}
                {message === FormStatus.ErrorLocal && (
                  <div ref={errorRef} tabIndex={0}>
                    <Alert noIcon className='passwordReset-alert' type='error'>
                      Please enter a username.
                    </Alert>
                  </div>
                )}
                {message !== FormStatus.Success && (
                  <>
                    <Label>
                      Please enter your username and we will email you a link to
                      reset your password.
                    </Label>
                    <Label htmlFor='username'>Username</Label>
                    <TextInput
                      id='username'
                      name='username'
                      type='text'
                      autoCapitalize='off'
                      autoCorrect='off'
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <Button
                      type='submit'
                      role='button'
                      id='reset-form-button'
                      disabled={isLoading}
                    >
                      {isLoading ? 'Resetting...' : 'Reset Password'}
                    </Button>
                  </>
                )}
                <div className='passwordReset-backToLogin'>
                  <a href='login' title='Back to Sign In'>
                    Back to Sign In
                  </a>
                </div>
              </Fieldset>
            </Form>
            <img
              className='login-granicusLogo'
              src={GranicusLogoMixed}
              aria-hidden='true'
            />
          </div>
        </div>
      </div>
    </>
  );
}

PasswordResetForm.displayName = 'Password Reset Form';
