import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useGetVideosQuery } from '../../services/videoApi';
import IndexTable from '../Common/IndexTable';
import { selectLastSyncedVideos } from '../../common/syncStatusSlice';
import settings from '../../common/settings';
import PaginationByMonth from '../Common/PaginationByMonth';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { getDateInOffsetTime } from '../../helpers/dayjsHelpers';
import { selectSelectedMonth, setSelectedMonth } from './videoSlice';
import classNames from 'classnames';
import { selectExpanded } from '../Navigation/navigationSlice';

const formatVideos = (videos) =>
  (videos || []).map((video) => {
    const dateInOffsetTime = getDateInOffsetTime(video.date);
    return {
      name: video.name,
      date: dateInOffsetTime.format(settings.monthDisplayFormat),
      time: dateInOffsetTime.format(settings.timeDisplayFormat),
      id: video.id,
      uid: video.uid,
    };
  });

const tableHeadings = ['Meeting Name', 'Date', 'Time'];
export default function VideosIndex() {
  let history = useHistory();
  const dispatch = useDispatch();

  const selectedMonth = useSelector(selectSelectedMonth);
  const startTime = dayjs(selectedMonth, settings.paginationDateFormat)
    .startOf('month')
    .format(settings.memaStartTimeUrlParamFormat);
  const endTime = dayjs(selectedMonth, settings.paginationDateFormat)
    .endOf('month')
    .format(settings.memaEndTimeUrlParamFormat);

  const {
    data: { videos = [] } = {},
    isLoading,
    isFetching,
    isSuccess,
    refetch,
  } = useGetVideosQuery({ startTime, endTime });
  const updatedTime = useSelector(selectLastSyncedVideos);
  const expanded = useSelector(selectExpanded);

  const dataStatus = isLoading
    ? 'initializing'
    : isFetching
      ? 'loading'
      : isSuccess
        ? 'loaded'
        : 'error';

  useEffect(() => {
    return () => {
      // reset seletedMonth while leaving the video index page
      dispatch(setSelectedMonth(undefined));
    };
  }, [dispatch]);

  const handleClickRow = (videoId) => {
    const video = videos.find((video) => video.id === videoId);
    history.push({
      pathname: `/videos/${video.id}`,
      state: {
        month: selectedMonth,
        prevPath: '/videos',
      },
    });
  };

  const pageChangeHandler = (month) => {
    dispatch(setSelectedMonth(month));
  };

  return (
    <div id={'videos-container'}>
      <div className="table-content video-content margin-bottom-8">
        <IndexTable
          dataType={'videos'}
          captionText={'Select an archived meeting to view the video.'}
          emptyDataMsg={'There are no videos to list.'}
          tableHeadings={tableHeadings}
          tableData={formatVideos(videos)}
          dataStatus={dataStatus}
          handleClickRow={handleClickRow}
          reload={refetch}
          loadedDataTime={updatedTime}
        />
      </div>
      {isSuccess && selectedMonth && (
        <div
          className={classNames('pagination', 'videos-pagination', {
            expanded: !expanded,
          })}
        >
          <PaginationByMonth
            selected={selectedMonth}
            onChange={pageChangeHandler}
            previousOffsetMonths={0}
            nextOffsetMonths={0}
          />
        </div>
      )}
    </div>
  );
}

VideosIndex.displayName = 'Videos Index';
