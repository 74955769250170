import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const settingApi = createApi({
  reducerPath: 'settingApi',
  baseQuery: baseQuery,
  keepUnusedDataFor: 10,
  endpoints: (builder) => ({
    getSetting: builder.query({
      query: (key) =>
        `/api/setting/${key}`,
      transformResponse: (response) => response.data
    }),
  }),
});

export const { useGetSettingQuery } = settingApi;
