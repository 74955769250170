// These data will be migrated to either devops(Atlas) driven or gitlab variables in future.
// or, this file will be directly replaced in the artifactory during CI/CD, as aws-exports.js is an AWS generated file and can be downloaded for a particular appsync api from AWS directly

const config = {
    aws_appsync_graphqlEndpoint: 'https://tp3slcdnnfej3ckdt7kv5v6zpq.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: 'da2-cvbtx4vytzap3onxnuxmprpeni',
};
  
export default config;