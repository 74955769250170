import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const videoApi = createApi({
  reducerPath: 'videoApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getVideos: builder.query({
      query: ({ startTime, endTime }) =>
        `/api/legislate/videos?startTime=${startTime}&endTime=${endTime}`,
    }),
    getVideo: builder.query({
      query: (id) => ({ url: `/api/legislate/videos/${id}` })
    })
  })
});

export const { useGetVideosQuery, useGetVideoQuery } = videoApi;
