import { createSlice } from '@reduxjs/toolkit';
import { agendaApi } from '../../services/agendaApi';
import dayjs from 'dayjs';
import settings from '../../common/settings';

const initialState = {
  list: [],
  selectedMonth: dayjs().format(settings.paginationDateFormat),
};

const agenda = createSlice({
  name: 'agenda',
  initialState,
  reducers: {
    setSelectedMonth: (state, { payload }) => {
      state.selectedMonth = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      agendaApi.endpoints.getAgendas.matchFulfilled,
      (state, { payload }) => {
        state.list = payload;
      }
    );
  },
});

export default agenda.reducer;

export const { setSelectedMonth } = agenda.actions;

export const selectAgendas = (state) => state.agendas.list;

export const selectSelectedMonth = (state) =>
  state.agendas.selectedMonth || dayjs().format(settings.paginationDateFormat);
