import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const noteApi = createApi({
  reducerPath: 'noteApi',
  baseQuery: baseQuery,
  tagTypes: ['ActiveAgendaNotes', 'Notes'],
  endpoints: (builder) => ({
    getActiveAgendaNotes: builder.query({
      query: (uid) => `legislate/api/council_notes/agenda/${ uid }`,
      providesTags: [{ type: 'ActiveAgendaNotes', id: 'LIST' }],
    }),
    getNotes: builder.query({
      query: ({ startTime, endTime }) => `legislate/api/council_notes?startTime=${ startTime }&endTime=${ endTime }`,
      providesTags: [{ type: 'Notes', id: 'LIST' }],
    }),
    upsertNote: builder.mutation({
      query: (params) => {
        return ({
          url: `legislate/api/council_notes/${ params.uid }`,
          method: 'PUT',
          body: { note: params.note },
        });
      },
      invalidatesTags: [
        { type: 'ActiveAgendaNotes', id: 'LIST' },
        { type: 'Notes', id: 'LIST' },
      ],
    }),
  })
});

export const { useGetActiveAgendaNotesQuery, useGetNotesQuery, useUpsertNoteMutation } = noteApi;