import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { agendaApi } from '../services/agendaApi';
import { bookmarkApi } from '../services/bookmarkApi';
import { noteApi } from '../services/noteApi';
import { customerApi } from '../services/customerApi';
import { videoApi } from '../services/videoApi';
import rootReducer from './rootReducer';
import { settingApi } from '../services/settingApi';
import { authApi } from '../services/authApi';

// https://redux-toolkit.js.org/api/configureStore
const store = configureStore({
  reducer: rootReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      agendaApi.middleware,
      bookmarkApi.middleware,
      noteApi.middleware,
      videoApi.middleware,
      customerApi.middleware,
      settingApi.middleware,
      authApi.middleware
    ]),
  devTools: { trace: true, traceLimit: 25 },
});

// configure listeners using the provided defaults
// enable refetchOnFocus, refetchOnReconnect... behaviors
setupListeners(store.dispatch);

global.store = store;

export default store;
