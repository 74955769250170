import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const bookmarkApi = createApi({
  reducerPath: 'bookmarkApi',
  baseQuery: baseQuery,
  tagTypes: ['ActiveAgendaBookmarks', 'Bookmarks'],
  endpoints: (builder) => ({
    getActiveAgendaBookmarks: builder.query({
      query: (uid) => `legislate/api/bookmarks/agenda/${uid}`,
      providesTags: [{ type: 'ActiveAgendaBookmarks', id: 'LIST' }],
    }),
    getBookmarks: builder.query({
      query: ({ startTime, endTime }) => `legislate/api/bookmarks?startTime=${startTime}&endTime=${endTime}`,
      providesTags: [{ type: 'Bookmarks', id: 'LIST' }],
    }),
    addBookmark: builder.mutation({
      query: (uid) => ({
        url: `legislate/api/bookmarks/${uid}`,
        method: 'POST',
      }),
      invalidatesTags: [
        { type: 'ActiveAgendaBookmarks', id: 'LIST' },
        { type: 'Bookmarks', id: 'LIST' },
      ],
    }),
    deleteBookmark: builder.mutation({
      query: (uid) => ({
        url: `legislate/api/bookmarks/${uid}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'ActiveAgendaBookmarks', id: 'LIST' },
        { type: 'Bookmarks', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetActiveAgendaBookmarksQuery,
  useGetBookmarksQuery,
  useAddBookmarkMutation,
  useDeleteBookmarkMutation,
} = bookmarkApi;
