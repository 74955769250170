import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  agenda: {},
  itemsDictionary: {},
  itemKeys: [],
  selectedItemKey: undefined,
  notes: {},
  bookmarks: [],
  enableErrorNotification: false,
};

const activeAgenda = createSlice({
  name: 'activeAgenda',
  initialState,
  reducers: {
    setSelectedItemKey: (state, { payload }) => {
      state.selectedItemKey = payload;
    },
    setActiveAgenda: (state, { payload: [agenda, notes, bookmarks] }) => {
      state.agenda = agenda;
      state.notes = notes;
      state.bookmarks = bookmarks;
    },
    setItemsDictionary: (state, { payload }) => {
      state.itemsDictionary = payload;
    },
    setItemKeys: (state, { payload }) => {
      state.itemKeys = payload;
    },
    setNote: (state, { payload }) => {
      state.notes[payload.itemUid] = payload.note;
    },
    setEnableActiveAgendaErrorNotification: (state, { payload }) => {
      state.enableErrorNotification = payload;
    },
    resetData: () => initialState,
  },
});

export default activeAgenda.reducer;
export const {
  setSelectedItemKey,
  setActiveAgenda,
  setItemsDictionary,
  setItemKeys,
  setNote,
  setNextItem,
  setPreviousItem,
  resetData,
  setEnableActiveAgendaErrorNotification
} = activeAgenda.actions;

export const selectActiveAgenda = (state) => state.activeAgenda.agenda;
export const selectItemKeys = (state) => state.activeAgenda.itemKeys;
export const selectSelectedItemKey = (state) =>
  state.activeAgenda.selectedItemKey;
export const selectAgendaBookmarks = (state) => state.activeAgenda.bookmarks;
export const selectAgendaNotes = (state) => state.activeAgenda.notes;

export const selectSelectedItem = createSelector(
  [(state) => state.activeAgenda.itemsDictionary, (_, itemUid) => itemUid],
  (itemsDictionary, itemUid) => (itemUid ? itemsDictionary[itemUid] : null)
);

export const selectNote = createSelector(
  [(state) => state.activeAgenda.notes, (_, itemUid) => itemUid],
  (notes, itemUid) => (itemUid ? notes[itemUid] : null)
);

export const selectEnableErrorNotification = (state) =>
  state.activeAgenda.enableErrorNotification;