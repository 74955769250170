/**
 * This file contains the container component for the ItemDetail component.
 * It manages the state and behavior of the ItemDetail, including rendering
 * 1. Next and Previous buttons for navigating agenda items
 * 2. Tabs for displaying the agenda item and speakers
 */

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs } from 'antd';
import { Button, CardHeader } from '@trussworks/react-uswds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  selectItemKeys,
  selectSelectedItem,
  selectSelectedItemKey,
  setSelectedItemKey,
} from '../activeAgendaSlice';
import { setActiveTab } from '../../../common/liveMeetingSpeakerSlice';
import AgendaItemDetail from './AgendaItemDetail';
import Speakers from './Speakers';
import { selectIsCloudVotingEnabled } from "../../../common/settingSlice";

export default function Container() {
  const dispatch = useDispatch();

  const selectedItemKey = useSelector(selectSelectedItemKey);
  const itemKeys = useSelector(selectItemKeys);
  const activeTab = useSelector(state => state.liveMeetingSpeaker.activeTab)
  const isCloudVotingEnabled = useSelector(selectIsCloudVotingEnabled);

  const item = useSelector((state) =>
    selectSelectedItem(state, selectedItemKey)
  );

  const isFirstItem = () =>
    !item || itemKeys.findIndex((i) => i === selectedItemKey) === 0;
  const isLastItem = () =>
    !item ||
    itemKeys.findIndex((i) => i === selectedItemKey) === itemKeys.length - 1;

  const handleNextClick = () => {
    if (isLastItem()) {
      return;
    }
    const nextIndex = itemKeys.findIndex((i) => i === selectedItemKey) + 1;
    dispatch(setSelectedItemKey(itemKeys[nextIndex]));
  };

  const handlePrevClick = () => {
    if (isFirstItem()) {
      return;
    }
    const prevIndex = itemKeys.findIndex((i) => i === selectedItemKey) - 1;
    dispatch(setSelectedItemKey(itemKeys[prevIndex]));
  };

  const prevButton = (
    <Button
      type="button"
      className="item-nav-button"
      unstyled
      onClick={handlePrevClick}
      disabled={isFirstItem()}
    >
      <FontAwesomeIcon
        icon={'angle-left'}
        size={'lg'}
        style={{ marginRight: 4 }}
      />
      <span>Previous Item</span>
    </Button>
  );

  const nextButton = (
    <Button
      type="button"
      className="item-nav-button"
      unstyled
      onClick={handleNextClick}
      disabled={isLastItem()}
    >
      <span>Next Item</span>
      <FontAwesomeIcon
        icon={'angle-right'}
        size={'lg'}
        style={{ marginLeft: 4 }}
      />
    </Button>
  );

  const handleTabChange = (key) => {
    dispatch(setActiveTab(key));
  };

  return (
    <div className="agenda-item-details-container">
      <CardHeader className="item-detail-header display-flex">
        <div className="flex-auto">{prevButton}</div>
        <div className="flex-auto">{nextButton}</div>
      </CardHeader>
      <Tabs
        defaultActiveKey={activeTab}
        onChange={handleTabChange}
        className="item-details-content"
        items={[
          {
            label: 'Item Details',
            key: '1',
            children: <AgendaItemDetail />,
          },
          isCloudVotingEnabled && {
            label: 'Speakers',
            key: '2',
            children: <Speakers />,
          },
        ].filter(Boolean)}
      />
    </div>
  );
}
