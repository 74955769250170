import React, { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const ArchivePlayer = ({
  src,
  captionsUrl,
  timestamps
}) => {

  if (!window.flowplayer) return (
    <div id="video-player-container">
      flowplayer could not be loaded
    </div>
  )

  const flowplayerRef = createRef();

  useEffect(() => {
    const options = {
      autoplay: false,
      live: false,
      src: { src },
      subtitles: {
        tracks: [
          {
            type: "captions",
            label: "English",
            lang: "en",
            default: false,
            src: captionsUrl
          }
        ]
      },
      seekable: true,
      // eslint-disable-next-line max-len
      token: "eyJraWQiOiJScVFLZVVmeUFTdjAiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6NixcImlkXCI6XCJScVFLZVVmeUFTdjBcIn0iLCJpc3MiOiJGbG93cGxheWVyIn0.vPScDB7gPUDni7WRtdG9HYx29dkyCNnb6A5d70RdoOajH3mR7orevBjTRelBl0zYI7asLUUiXI1LKkgDu2UKaQ"
    }
    const player = window.flowplayer('#flowplayer', options)

    const flowplayerContainer = document.getElementById("flowplayer");

    // Following is a workaround to make fullscreen work on iPad PWA.
    // Find the fullscreen and exitFullscreen buttons within the flowplayer container
    const fullscreenButton = player.root.querySelector('.fp-fullscreen');
    const exitFullscreenButton = player.root.querySelector('.fp-fullscreen-exit');

    // Add the 'is-fullscreen' class to the flowplayer container when the fullscreen button is clicked
    fullscreenButton.onclick = function() {
      flowplayerContainer.classList.add('is-fullscreen');
    }

    // Remove the 'is-fullscreen' class from the flowplayer container when the exitFullscreen button is clicked
    exitFullscreenButton.onclick = function() {
      flowplayerContainer.classList.remove('is-fullscreen');
    }

    // Find the reference element we need to place seek buttons
    const live_status = player.root.querySelector(".fp-live-status")

    // Create custom seek buttons
    const backward_button = document.createElement("span")
    const forward_button = document.createElement("span")

    // Style custom seek buttons
    // eslint-disable-next-line max-len
    backward_button.innerHTML = '<span id="granicus-back" class="fa-layers fa-fw"><i class="fas fa-undo-alt" data-fa-transform="rotate--315 grow-15"></i><span class="fa-layers-text fa-inverse" data-fa-transform="shrink-5 down-10 right-9" style="font-weight:900">15</span></span>'
    // eslint-disable-next-line max-len
    forward_button.innerHTML = '<span id="granicus-forward" class="fa-layers fa-fw"><span class="fa-layers-text fa-inverse" data-fa-transform="shrink-5 down-10 right-9" style="font-weight:900">15</span><i class="fas fa-redo-alt" data-fa-transform="rotate--45 grow-16"></i></span>'

    // Add custom seek buttons
    live_status.before(forward_button)
    forward_button.before(backward_button)

    // Custom seek button onclick functions
    backward_button.onclick = function() {
      player.currentTime = player.currentTime - 15;
    }
    forward_button.onclick = function() {
      player.currentTime = player.currentTime + 15;
    }

    function jumpToTimestamp(seconds) {
      const row = document.getElementById(seconds)
      row.onclick = function() {
        // player has not started loading content
        if (player.readyState === 0) player.setOpts({ start_time: seconds })
        // player has already started loading content
        if (player.readyState > 0) player.currentTime = seconds
        // attempt to play content
        player.togglePlay(true)
      }
    }

    // extract all the timestamp times
    const times = [];
    timestamps.forEach((timestamp) => {
      times.push(timestamp.entrytime)
    })
    // bind the rows to the times
    times.forEach((time) => {
      jumpToTimestamp(time)
    })
  }, []);

  // empty div is like holding still so React won't see you
  // (DOM changes for player construction can happen freely within)
  return (
    <div id="video-player-container">
      <div id="flowplayer" ref={flowplayerRef}></div>

    </div>
  )
};

ArchivePlayer.propTypes = {
  src: PropTypes.string.isRequired,
  captionsUrl: PropTypes.string.isRequired,
  timestamps: PropTypes.array.isRequired
};

ArchivePlayer.displayName = 'ArchivePlayer';

export default ArchivePlayer;