import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: isLoggedIn(),
  customer_uid: '',
  username: undefined,
  personUid: ''
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, { payload }) => {
      state.isLoggedIn = setGatCookie(payload);
    },
    logout: (state) => {
      state.isLoggedIn = clearGatCookie();
      state.username = undefined;
    },
    setCustomerId: (state, { payload }) => {
      state.customer_uid = payload;
    },
    setUsername: (state, { payload }) => {
      state.username = payload;
    },
    setPersonUid: (state, { payload }) => {
      state.personUid = payload;
    },
  },
});

function isLoggedIn() {
  return getGatCookie() ? true : false;
}

function getGatCookie() {
  const gat = document.cookie
    .split("; ")
    .find((row) => row.startsWith("_gat="));

  if (gat) return gat.split("=")[1];
}

function clearGatCookie() {
  document.cookie = `_gat=; max-age=-1; path=/;`;
  document.cookie = `PHPSESSID=; max-age=-1; path=/;`;
  return false;
}

function setGatCookie(gat) {
  if (!gat) return false;

  return true;
}

export const selectUser = (state) => state.auth;

export const { setCredentials, logout, setCustomerId, setUsername, setPersonUid } = auth.actions;

export default auth.reducer;
