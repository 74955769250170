import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  expanded: true
};

const navigation = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    toggleExpanded: (state) => {
      state.expanded = !state.expanded
    }
  }
});

export const { toggleExpanded } = navigation.actions

export const selectExpanded = (state) => state.navigation.expanded;

export default navigation.reducer;