import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
// https://reactcommunity.org/react-modal/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Modal = (props) => {
  /**
   * NOTE:
   * The close transition only works if you use the isOpen prop
   * to toggle the visibility of the modal. Don't conditionally
   * render the Modal, e.g. {open && <Modal />}
   * The close delay can be completely removed via the
   * noCloseTransition prop being true.
   */

  // Required for accessibility - sets the app to aria-hidden
  ReactModal.setAppElement('#legislate');
  const {
    contentClassName,
    overlayClassName,
    isOpen,
    onRequestClose,
    onAfterClose,
    closeIcon,
    headerContent,
    bodyContent,
    footerContent,
    contentLabel,
    noCloseTransition,
    ...restProps
  } = props;

  let mergedClassName = null;
  let mergedOverlayClassName = null;

  const defaultClassName = {
    base: 'leg-modal-content',
    afterOpen: 'leg-modal-content--after-open',
    beforeClose: 'leg-modal-content--before-close',
  };

  if (contentClassName) {
    mergedClassName =
      typeof contentClassName === 'string'
        ? {
          ...defaultClassName,
          ...{ base: `leg-modal-content ${contentClassName}` },
        }
        : { ...defaultClassName, ...contentClassName };
  }

  const defaultOverlayClassName = {
    base: 'leg-modal-overlay',
    afterOpen: 'leg-modal-overlay--after-open',
    beforeClose: 'leg-modal-overlay--before-close',
  };

  if (overlayClassName) {
    mergedOverlayClassName =
      typeof overlayClassName === 'string'
        ? {
          ...defaultOverlayClassName,
          ...{ base: `leg-modal-overlay ${overlayClassName}` },
        }
        : { ...defaultOverlayClassName, ...overlayClassName };
  }

  const defaultHeader = <h2 className="margin-0">{contentLabel}</h2>;

  return (
    <ReactModal
      {...restProps}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      // Use for any side-effects that must run after modal unmounts
      onAfterClose={onAfterClose}
      className={mergedClassName ?? defaultClassName}
      overlayClassName={mergedOverlayClassName ?? defaultOverlayClassName}
      contentLabel={contentLabel}
      // Must match or be very close to transition value in modal.scss
      closeTimeoutMS={noCloseTransition ? 0 : 200}
      testId="reactModalContent"
    >
      <div className="leg-modal-header">
        {!headerContent && defaultHeader}
        {headerContent}
        <button
          type="button"
          aria-label="Close"
          className="leg-modal-close"
          onClick={onRequestClose}
        >
          {closeIcon}
        </button>
      </div>
      <div className="leg-modal-body">{bodyContent}</div>
      {footerContent && <div className="leg-modal-footer">{footerContent}</div>}
    </ReactModal>
  );
};

Modal.displayName = 'Modal';

Modal.defaultProps = {
  isOpen: false,
  closeIcon: <FontAwesomeIcon icon={faTimes} size={'lg'} />,
  onAfterClose: () => {},
  noCloseTransition: false
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  contentLabel: PropTypes.string.isRequired,
  bodyContent: PropTypes.element.isRequired,
  closeIcon: PropTypes.object,
  onAfterClose: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  headerContent: PropTypes.element,
  // eslint-disable-next-line react/require-default-props
  footerContent: PropTypes.element,
  // eslint-disable-next-line react/require-default-props
  contentClassName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      base: PropTypes.string.isRequired,
      afterOpen: PropTypes.string.isRequired,
      beforeClose: PropTypes.string.isRequired,
    }),
  ]),
  // eslint-disable-next-line react/require-default-props
  overlayClassName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      base: PropTypes.string.isRequired,
      afterOpen: PropTypes.string.isRequired,
      beforeClose: PropTypes.string.isRequired,
    }),
  ]),
  noCloseTransition: PropTypes.bool
};

export default Modal;
