import { createSlice } from '@reduxjs/toolkit';
import { noteApi } from '../../services/noteApi';
import dayjs from 'dayjs';
import settings from '../../common/settings';

const initialState = {
  list: [],
  selectedMonth: dayjs().format(settings.paginationDateFormat),
};

const note = createSlice({
  name: 'note',
  initialState,
  reducers: {
    setSelectedMonth: (state, { payload }) => {
      state.selectedMonth = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      noteApi.endpoints.getNotes.matchFulfilled,
      (state, { payload }) => {
        state.list = payload;
      }
    );
  },
});

export default note.reducer;
export const { setSelectedMonth } = note.actions;

export const selectNotes = (state) => state.notes.list;

export const selectSelectedMonth = (state) =>
  state.notes.selectedMonth || dayjs().format(settings.paginationDateFormat);