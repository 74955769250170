import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const OFFSET_REGEX = /([-|+]\d\d:\d\d)/;
/*
  @param {string} ISO8601 dateTimeString
  ISO 8601 Format from mema: YYYY-MM-DDTHH:mm:ss-/+hh:mm
  @returns {dayjs} object
*/
export const getDateInOffsetTime = (dateString) => {
  // This should never happen; but, because the caller is
  // expecting a dayjs object, we'll return a dayjs object
  // that will render 'Invalid Date' on obj.format() calls.
  if (!dateString) return dayjs.utc('');
  try {
    let match = dateString.match(OFFSET_REGEX);
    if (match) {
      let offsetStr = match[0];
      let operator = offsetStr[0];
      let [hours, mins] = offsetStr.substring(1).split(':');
      let offset = (parseInt(hours)*60) + parseInt(mins);
      return dayjs
        .utc(dateString)
        .utcOffset(parseInt(`${operator}${offset}`));
    }
    // Fallback to end-user/browser time
    return dayjs.utc(dateString).local();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `getDateInClientTZ Error on dateString: ${dateString}. Error: ${error}`
    );
    // Fallback to end-user/browser time
    return dayjs.utc(dateString).local();
  }
};
