import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  calledSpeaker: { 
    agenda_uid: '',
    customer_uid: '',
    duration: '',
    item_uid: '',
    name: '',
    position: '',
    speaker_type: '',
    start_time: '',
    status: '',
    uid: ''
},
  speakers: [],
  activeTab: '1'
};

const liveMeetingSpeaker = createSlice({
  name: 'speaker',
  initialState,
  reducers: {
    setSpeakers: (state, { payload }) => {
      state.speakers = payload;
    },
    setCalledSpeaker: (state, { payload }) => {
      state.calledSpeaker = payload;
    },
    deleteSpeaker: (state, { payload }) => {
      const index = state.speakers.findIndex(sp => sp.uid === payload);
      if (index !== -1) {
        state.speakers.splice(index, 1);
      }
    },
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
  }
});

export default liveMeetingSpeaker.reducer;

export const {
  setSpeakers,
  setCalledSpeaker,
  deleteSpeaker,
  setActiveTab
} = liveMeetingSpeaker.actions;


