import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from '@trussworks/react-uswds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import RefreshButton from './RefreshButton';
import { Button } from '@trussworks/react-uswds';
import { CvMeetingLiveIcon } from "../../../../assets/images/legislate/imageIndex";
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { EventStateEnum } from '../../../constants/eventConstants';
import { MeetingStatus, AvailabilityStatus } from '../../../constants/cloudVotingConstants';
import { setMemberDetails, setMotionDetails } from '../../common/liveMeetingSlice';
import GraphQLAPI, { graphqlOperation } from '@aws-amplify/api';
import { getAgendaList, getMembersByDisplayName , getAgendaListByMeetingGuid, getVotecastCustomerId } from '../../../../amplify/graphql/queries';
import { updateMemberAvailabilityStatus } from '../../../../amplify/graphql/mutations';
import { setEnableActiveAgendaErrorNotification } from "../ActiveAgenda/activeAgendaSlice";
import { useGetCurrentUserQuery /*, useGetCurrentPersonUidQuery */ } from '../../services/authApi';
import { setCustomerId, setUsername, setPersonUid } from '../Auth/authSlice';


export default function IndexTable(props) {
  const {
    dataType = '',
    captionText = '',
    emptyDataMsg = '',
    tableHeadings = [],
    tableData = [],
    dataStatus = '',
    handleClickRow,
    reload,
    loadedDataTime,
    cloudVotingEnabled,
  } = props;

  const dispatch = useDispatch();
  const [meetingOpenToJoin, setMeetingOpenForJoin] = useState([]);
  let customerId = useSelector(state => state.auth.customer_uid);
  const userName = useSelector(state => state.auth.username);
  const personUid = useSelector(state => state.auth.personUid);

  const { data: currentUserData, isSuccess: isCurrentUserSuccess, isError: isCurrentUserError } = useGetCurrentUserQuery(undefined, {
    skip: !cloudVotingEnabled,
  });

  if (cloudVotingEnabled && isCurrentUserError) {
    console.error("Error loading current user data.");
    return null;
  }

  useEffect(() => {
    if (cloudVotingEnabled && isCurrentUserSuccess && currentUserData) {
      dispatch(setCustomerId(currentUserData?.customer_uid));
      dispatch(setUsername(currentUserData?.user?.table?.fullname));
    }
  }, [cloudVotingEnabled, isCurrentUserSuccess])

  // Commenting out fetching and setting of current person UID based on user name
  // const currentUserName = currentUserData ? currentUserData?.user?.table?.fullname : '';
  // const { data: currentPersonUidData, isSuccess: isCurrentPersonUidSuccess } = useGetCurrentPersonUidQuery(currentUserName, {
  //   skip: !cloudVotingEnabled || !currentUserName,
  // });
  // useEffect(() => {
  //   if (cloudVotingEnabled && isCurrentPersonUidSuccess ) {
  //     dispatch(setPersonUid(currentPersonUidData[0]?.uuid));
  //   }
  // }, [isCurrentPersonUidSuccess])

  const handleKeyPress = (e, id) => {
    e.preventDefault();
    if (e.key === 'Enter') {
      handleClickRow(id);
    }
  };

  const meetingsOpenToJoinForAttendee = async() => {
    const customerDomain = window.location.host;
    if (customerDomain !== 'mm.lvh.me') {
      const getCustomerIdResponse = await GraphQLAPI.graphql(graphqlOperation(getVotecastCustomerId, { domain: customerDomain }));
      const customerData = getCustomerIdResponse?.data?.getVotecastCustomerId;
      if (customerData && customerData.uid) {
        customerId = customerData.uid;
      }
    }

    const meetingsResponse = await GraphQLAPI.graphql(graphqlOperation(getAgendaList, { customer_uid: customerId }));
    const meetings = meetingsResponse?.data?.getVotecastAgendaByCustomerId;
    if (meetings && meetings.length > 0) {
      const openMeetings = meetings.filter(m => m.meeting_status === MeetingStatus.OPEN || m.meeting_status === MeetingStatus.RUNNING);

      if (openMeetings.length > 0) {
        for (const meeting of openMeetings) {
          const membersResponse = await GraphQLAPI.graphql(graphqlOperation(getMembersByDisplayName, {
            customer_uid: customerId,
            agenda_uid: meeting.uid,
            person_uid: personUid,
            display_name: userName
          }));
          const memberData = membersResponse?.data?.getVotecastMembersByDisplayName;

          let personIdMatch = false;
          if (memberData && memberData.length > 0) {
            for (const member of memberData) {
              if (member.person_uid === personUid || member.display_name === userName) {
                dispatch(setMemberDetails({ memberJoinStatus: member.availability_status === AvailabilityStatus.JOINED }));
                setMeetingOpenForJoin(prevState => [...prevState,
                  { meetingId: meeting.meeting_guid, memberId: member.uid, availabilityStatus: member.availability_status, votecastAgendaUid: meeting.uid }
                ]);
                personIdMatch = true;
                break;
              }
            }

            if (!personIdMatch) {
              dispatch(setMemberDetails({ memberJoinStatus: memberData[0].availability_status === AvailabilityStatus.JOINED }));
              setMeetingOpenForJoin(prevState => [...prevState,
                { meetingId: meeting.meeting_guid, memberId: memberData[0].uid, availabilityStatus: memberData[0].availability_status, votecastAgendaUid: meeting.uid }
              ]);
            }
          }
        }
      }
    }
  };

  useEffect (() => {
    if (cloudVotingEnabled && customerId)
    {
      meetingsOpenToJoinForAttendee();
    }
    // Cleanup function
    return () => {
      setMeetingOpenForJoin([]);
    };
  }, [cloudVotingEnabled, customerId]);

  const renderDynamicHeadings = () => {
    return tableHeadings.map((heading, index) => {
      return (
        <th
          key={heading}
          data-testid={index + heading}
          className={'index-table-headings'}
        >
          {heading}
        </th>
      );
    });
  };

  const updateMemberJoinedInput = (meetingId) => ({
    customer_uid: customerId,
    uid: meetingOpenToJoin.find(meeting => meeting.votecastAgendaUid === meetingId)?.memberId,
    availability_status: AvailabilityStatus.JOINED
  });

  const handleJoinMeetingClick = async(id, uid) => {

    const getMeetingResponse = await GraphQLAPI.graphql(graphqlOperation(getAgendaListByMeetingGuid, { customer_uid: customerId, meeting_guid: uid }));
    const meetingData = getMeetingResponse?.data?.getVotecastAgendaByMeetingGuid;

    if (!meetingData || meetingData.length === 0) {
      dispatch(setEnableActiveAgendaErrorNotification(true));
      return;
    }

    const filteredAndSortedMeetingData = meetingData
      ?.filter(item => item.meeting_status === MeetingStatus.OPEN || item.meeting_status === MeetingStatus.RUNNING)
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    if (filteredAndSortedMeetingData?.length > 0) {
      const liveVotecastAgendaUid = filteredAndSortedMeetingData[0]?.uid;
      const updateMemberResponse = await GraphQLAPI.graphql(graphqlOperation(updateMemberAvailabilityStatus, { input: updateMemberJoinedInput(liveVotecastAgendaUid) }));
      const updateMemberData = updateMemberResponse?.data?.updateVotecastMemberAvailabilityStatus;

      if (updateMemberData && updateMemberData.availability_status === AvailabilityStatus.JOINED) {
        dispatch(setMemberDetails({ memberJoinStatus: true }));
        setMeetingOpenForJoin(prevState => prevState.map(meeting =>
          meeting.votecastAgendaUid === uid ? { ...meeting, availabilityStatus: AvailabilityStatus.JOINED } : meeting
        ));
        dispatch(setMotionDetails({ allowMotions:true }));
      }
      handleClickRow(id);
    }
    else {
      //show Join error message
      dispatch(setEnableActiveAgendaErrorNotification(true));
    }
  };

  const isMeetingOpenToJoin = (meetingId) => {
    return meetingOpenToJoin.some(detail => detail.meetingId === meetingId);
  };

  const isJoined = (meetingId) => {
    const meetingDetail = meetingOpenToJoin.find(detail => detail.meetingId === meetingId);
    return meetingDetail ? meetingDetail.availabilityStatus === AvailabilityStatus.JOINED : false
  };

  const renderLiveIcon = (EventState) => {
    if (cloudVotingEnabled && EventState) {
      return null;
    }

    return <img className="padding-left-1"
      src={CvMeetingLiveIcon}
      alt='Live Meeting'
      id='agenda-live-meeting'
    />;
  };

  /*
  Make sure the props you are passing for headings and
  the data for the rows are in the same order that you want them to be displayed in,
  with ID as the last object property in the data (UID is not displayed in the table, but is used for onClick actions)
   */
  const renderDynamicCells = (rowData) => {
    return Object.keys(rowData).map((key) => {
      // Do not display the ID, UID in the table row
      if (key === 'uid' || key === 'id' || key === 'status') return null;
      const EventStatus = rowData.status === EventStateEnum.Running || rowData.status === EventStateEnum.Paused || rowData.status === EventStateEnum.Live;
      const isMeetingNameCol = key === 'name';
      return (
        <td key={key} title={rowData[key]}>
          {isMeetingNameCol && cloudVotingEnabled ? (
            <>
              {rowData[key]}
              {renderLiveIcon(!EventStatus)}
            </>
          ) : (
            rowData[key]
          )}
        </td>
      );
    });
  };

  const renderRow = (rowData, index) => {
    return (
      <tr
        key={index}
        tabIndex={index + 1}
        onClick={(e) => { e.stopPropagation(); handleClickRow(rowData.id); }}
        onKeyPress={(e) => { e.stopPropagation(); handleKeyPress(e, rowData.id) }}
        className={'index-table-row'}
        data-testid={index}
      >
        {renderDynamicCells(rowData)}
        <td>
          <a
            onClick={() => handleClickRow(rowData.id)}
            onKeyPress={(e) => handleKeyPress(e, rowData.id)}
          >
            { cloudVotingEnabled ? (
              ( isMeetingOpenToJoin(rowData.uid)) ? (
                isJoined(rowData.uid) ? (
                  <>
                    <FontAwesomeIcon
                      icon={faCheckCircle} size={'lg'}
                      style={{ marginRight: 8.5,color: 'green' }}
                    />
                    <span>Joined</span>
                  </>
                ) : (
                  <Button className="btn-blue-background" onClick={() => handleJoinMeetingClick(rowData.id, rowData.uid)}> Join Meeting</Button>
                )
              ) : (
                <Button className="btn-blue-background" disabled={true}> Join Meeting</Button>
              )
            ) : (
              <FontAwesomeIcon
                icon={'angle-right'}
                className={'icon-navigation'}
              />
            )
            }
          </a>
        </td>
      </tr>
    );
  };

  const renderTableBody = () => {
    if (dataStatus === 'loaded') {
      return tableData.map((rowData, index) => {
        return renderRow(rowData, index);
      });
    } else if (dataStatus === 'loading' || dataStatus === 'initializing') {
      return (
        <tr className={'index-table-non-linked-row'}>
          <td colSpan='3'>Loading...</td>
        </tr>
      );
    } else {
      return (
        <tr className={'index-table-non-linked-row'}>
          <td colSpan='3'>We encountered a problem while loading {dataType}</td>
        </tr>
      );
    }
  };

  const renderRefreshButton = () => {
    const isLoadingData =
      dataStatus === 'loading' || dataStatus === 'initializing';
    return dataStatus !== 'initializing' ? (
      <RefreshButton
        text={loadedDataTime && `Updated ${loadedDataTime}`}
        onClick={reload}
        isLoading={isLoadingData}
      />
    ) : <div></div>;
  };

  const renderTableCaption = () => {
    if (dataStatus === 'loaded') {
      return tableData.length === 0 ? (
        <div>
          <strong>{emptyDataMsg}</strong>
        </div>
      ) : (
        <div>{captionText}</div>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <>
      <div className="index-table-header">
        {renderTableCaption()}
        {renderRefreshButton()}
      </div>
      <Table fullWidth={true}>
        <thead>
          <tr
            className={'index-table-heading-row'}
            data-testid={'index-table-heading-row'}
          >
            {renderDynamicHeadings()}
            <th className={'index-table-action-column'}>View Details</th>
          </tr>
        </thead>
        <tbody>{renderTableBody()}</tbody>
      </Table>
    </>
  );
}

IndexTable.displayName = 'Index Table';

IndexTable.propTypes = {
  dataType: PropTypes.string.isRequired,
  captionText: PropTypes.string.isRequired,
  emptyDataMsg: PropTypes.string.isRequired,
  tableHeadings: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  dataStatus: PropTypes.string.isRequired,
  handleClickRow: PropTypes.func.isRequired,
  reload: PropTypes.func,
  loadedDataTime: PropTypes.string,
  cloudVotingEnabled: PropTypes.bool,
};

IndexTable.defaultProps = {
  reload: () => {},
  loadedDataTime: ''
};
