import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaginationByMonth from '../Common/PaginationByMonth';
import { useGetNotesQuery } from '../../services/noteApi'
import { Accordion } from "@trussworks/react-uswds";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoteTable from "./NoteTable";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { getDateInOffsetTime } from '../../helpers/dayjsHelpers';
import settings from '../../common/settings';
import classNames from 'classnames';
import { selectExpanded } from '../Navigation/navigationSlice';
import { selectSelectedMonth, setSelectedMonth } from './noteSlice';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export default function NoteIndex() {
  let history = useHistory();
  const dispatch = useDispatch();
  const selectedMonth = useSelector(selectSelectedMonth);
  const startTime = dayjs(selectedMonth, settings.paginationDateFormat)
    .startOf('month')
    .format(settings.memaStartTimeUrlParamFormat);
  const endTime = dayjs(selectedMonth, settings.paginationDateFormat)
    .endOf('month')
    .format(settings.memaEndTimeUrlParamFormat);
  const { data, isLoading, isFetching, isError, isSuccess } = useGetNotesQuery({ startTime, endTime });
  const expanded = useSelector(selectExpanded);
  const pageChangeHandler = (month) => {
    dispatch(setSelectedMonth(month));
  };

  const accordionEvents = () => {
    return (
      data.eventsWithNotes.events.map((event) => {
        // The event items from the GetNotesQuery are the items that have notes in agenda order. Use this to build the list of notes, and
        // pull in the note text from data.eventsWithNotes.councilNotesByItemUid looking up by item.uid
        const notes = event.items.map((item) => {
          return { id: item.id, uid: item.uid, title: item.name, note: data.eventsWithNotes.councilNotesByItemUid[item.uid] }
        })

        // click handler for each note row in the NoteTable. The URL needs the event uid, so we are creating it here instead of inside NoteTable.
        // Save the current page name and url in the history to enable the breadcrumb link on the agenda item page to return here.
        const handleClickRow = (itemUid) => {
          history.push({
            pathname: `agendas/${event.uid}/${itemUid}`,
            state: {
              month: selectedMonth,
              prevPath: '/notes',
            }
          });
        };
        const dateInOffsetTime = getDateInOffsetTime(event.starttime);
        return {
          // return an accordion item for our data array passed to Accordion
          id: event.id,
          title: `${event.name}, ${dateInOffsetTime.format(settings.monthAndTimeDisplayFormat)}`,
          content: <NoteTable items={notes} handleClickRow={handleClickRow} />,
          expanded: true,
          key: event.id,
        };
      })
    )
  }

  const renderContent = () => {
    if (isError) {
      return (
        <p className='accordion-error'>
          We encountered a problem while loading the notes.
          <br />
          Please try again later.
        </p>
      );
    }
    if (isLoading || isFetching) {
      return (
        <div
          className='display-flex flex-column flex-align-center flex-justify-center text-blue'
          style={{ minHeight: 200 }}
        >
          <FontAwesomeIcon
            icon={'spinner'}
            size={'2x'}
            className='fa-spin'
            style={{ marginBottom: 8 }}
          />
          Loading...
        </div>
      );
    }

    if (data.eventsWithNotes.events.length > 0)
      return <Accordion items={accordionEvents()} bordered={true} multiselectable={true} />
    else
      return <p>
        Notes were not found for this month.
      </p>

  };

  useEffect(() => {
    return () => {
      // reset seletedMonth while leaving the video index page
      dispatch(setSelectedMonth(undefined));
    };
  }, [dispatch]);

  return (
    <div id={'notes-container'}>
      <div id={'accordion-container'}>
        {renderContent()}
      </div>
      {isSuccess && selectedMonth && (
        <div
          className={classNames('pagination', 'videos-pagination', {
            expanded: !expanded,
          })}
        >
          <PaginationByMonth
            selected={selectedMonth}
            onChange={pageChangeHandler}
            previousOffsetMonths={0}
            nextOffsetMonths={0}
          />
        </div>
      )}
    </div>
  );
}

NoteIndex.displayName = 'Notes Index'


