import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
// https://reactcommunity.org/react-modal/
import '../../../../assets/stylesheets/legislate/navigation/logout_modal.scss'


const LogoutModal = (props) => {
  ReactModal.setAppElement(document.getElementById('legislate'));
  const {
    isOpen,
    handleConfirm,
    handleCancel,
  } = props;

  const body = (
    <div className="logout-modal-body">
      <p className="logout-modal-text-label">Are you sure you want to sign out?</p>
      <div className="logout-modal-button-group">
        <button type="button" className="logout-modal-button-yes"
          onClick={handleConfirm}>Yes</button>
        <button type="button" className="logout-modal-button-cancel"
          onClick={handleCancel}>Cancel</button>
      </div>
    </div>
  );

  const logoutClassName = {
    base: 'logout-modal-content',
    afterOpen: 'logout-modal-content--after-open',
    beforeClose: 'logout-modal-content--before-close',
  };

  const logoutOverlayClassName = {
    base: 'logout-modal-overlay',
    afterOpen: 'logout-modal-overlay--after-open',
    beforeClose: 'logout-modal-overlay--before-close',
  };

  return (
    <ReactModal
      isOpen={isOpen}
      closeTimeoutMS={300}
      contentLabel="Logout"
      className={logoutClassName}
      overlayClassName={logoutOverlayClassName}
      onRequestClose={handleCancel}
    >
      <div className="logout-modal-body">{body}</div>
    </ReactModal>
  );
};

LogoutModal.displayName = 'LogoutModal';

LogoutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

LogoutModal.defaultProps = {
  isOpen: false,
};

export default LogoutModal;
