import React from 'react';
import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom';
import GuardedRoute from './GuardedRoute';

import AgendaIndex from 'legislate/components/Agendas/AgendaIndex';
import ActiveAgenda from 'legislate/components/ActiveAgenda/ActiveAgenda';
import BookmarkIndex from 'legislate/components/Bookmarks/BookmarkIndex';
import NoteIndex from 'legislate/components/Notes/NoteIndex';
import LegislateHeader from 'legislate/components/Header/LegislateHeader';
import LoginForm from 'legislate/components/Auth/LoginForm';
import PasswordResetForm from 'legislate/components/Auth/PasswordResetForm';
import VideosIndex from 'legislate/components/Videos/VideosIndex';
import ActiveVideo from 'legislate/components/Videos/ActiveVideo';
import Navigation from 'legislate/components/Navigation/Navigation';
import PageNotFound from '../components/PageNotFound';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { selectExpanded } from '../components/Navigation/navigationSlice';

import config from '../../../amplify/aws-exports';
import Amplify from '@aws-amplify/core';
import PubSub from '@aws-amplify/pubsub';

Amplify.configure(config);

const App = () => {
  return (
    <BrowserRouter basename="/legislate" className="content">
      <Switch>
        <Route path="/login">
          <LoginForm />
        </Route>
        <Route path="/forgot_password">
          <PasswordResetForm />
        </Route>
        <Route path="/">
          <AppContainer />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

const AppContainer = () => {
  const expanded = useSelector(selectExpanded);
  return (
    <div id="legislate-wrapper" data-testid="app" className={expanded ? 'nav-expanded' : ''}>
      <section id="legislate-header" role="heading">
        <LegislateHeader />
      </section>
      <section id="legislate-navigation" role="navigation">
        <Navigation />
      </section>
      <section role="main" id="legislate-content">
        <Routes />
      </section>
      <ToastContainer />
    </div>
  );
};

AppContainer.displayName = 'App Container';

export function Routes() {
  return (
    <Switch>
      <GuardedRoute exact path="/">
        <Redirect to="/agendas" />
      </GuardedRoute>
      <GuardedRoute exact path={'/agendas'} title="Agendas">
        <AgendaIndex />
      </GuardedRoute>
      <GuardedRoute path="/agendas/:agenda_uid/:item_uid?" title="Agenda">
        <ActiveAgenda />
      </GuardedRoute>
      <GuardedRoute exact path="/notes" title="Notes">
        <NoteIndex />
      </GuardedRoute>
      <GuardedRoute exact path="/bookmarks" title="Bookmarks">
        <BookmarkIndex />
      </GuardedRoute>
      <GuardedRoute exact path="/videos" title="Videos">
        <VideosIndex />
      </GuardedRoute>
      <GuardedRoute path="/videos/:video_id/" title="Video">
        <ActiveVideo />
      </GuardedRoute>
      <GuardedRoute path="/*" title="404">
        <PageNotFound />
      </GuardedRoute>
    </Switch>
  );
}

App.displayName = 'App';
Routes.displayName = 'Routes';

export default App;
