import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CalledSpeaker from '../../LiveMeeting/Speakers/CalledSpeaker';
import { Button } from '@trussworks/react-uswds';
import DeleteConfirmation from '../../Common/DeleteConfirmation';
import { VotecastRequestOrigin, SpeakerStatus } from '../../../../constants/cloudVotingConstants';
import { updateVotecastSpeaker, deleteVotecastSpeaker } from '../../../../../amplify/graphql/mutations';
import { invokeGraphqlOperation } from '../../../../helpers/votecastWebHelpers/graphqlOperations';

export default function Speakers() {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

    const speakersList = useSelector((state) => state.liveMeetingSpeaker.speakers);
    const calledSpeaker = useSelector((state) => state.liveMeetingSpeaker.calledSpeaker);
    const isChair = useSelector(state => state.liveMeeting.memberDetails.isChair);
    const selectedItemKey = useSelector(state => state.activeAgenda.selectedItemKey);
    const itemsDictionary = useSelector(state => state.liveMeeting.agendaItemsDictionary);
    const selectedItemHypatia = itemsDictionary[selectedItemKey];
    const customerId = useSelector(state => state.auth.customer_uid);
    const votecastAgendaUid = useSelector(state => state.liveMeeting.votecastAgendaUid);

    const getSpeakersForSelectedItem = () => {
        return speakersList.filter(speaker => speaker.item_uid === selectedItemHypatia?.uid);
    };

    const isSpeaking = !!calledSpeaker?.name && selectedItemHypatia?.uid === calledSpeaker?.item_uid;

    const deleteSpeaker = async (speaker) => {
        await invokeGraphqlOperation(deleteVotecastSpeaker, { item_uid: speaker.item_uid, uid: speaker.uid, request_origin: VotecastRequestOrigin.ATTENDEE_DELETE });
        setIsDeleteConfirmationOpen(false);
    };

    const handleClearCurrentSpeaker = async () => {
        await invokeGraphqlOperation(deleteVotecastSpeaker,
            { item_uid: calledSpeaker.item_uid, uid: calledSpeaker.uid, request_origin: VotecastRequestOrigin.ATTENDEE_DELETE });
    };

    const callSpeaker = async (speaker) => {
        if (calledSpeaker && calledSpeaker.uid) {
            await handleClearCurrentSpeaker();
        }
        await invokeGraphqlOperation(updateVotecastSpeaker, {
            input: {
                ...speaker,
                customer_uid: customerId,
                agenda_uid: votecastAgendaUid,
                status: SpeakerStatus.CALLED,
                request_origin: VotecastRequestOrigin.ATTENDEE_CHAIR_UPDATE
            }
        });
    }

    return (
        <div>
            <hr className="horizontal-line" />
            {isChair && (
                <div className="clear-current-speaker-button">
                    <Button
                        type="button"
                        unstyled
                        onClick={handleClearCurrentSpeaker}
                        disabled={!isSpeaking}
                        className="clear-current-speaker-button"
                    >
                        <span className="clear-current-speaker-button-text">Clear Current Speaker</span>
                    </Button>
                </div>
            )}
            <CalledSpeaker />
            <div className="speaker-list">
                <ul>
                    {getSpeakersForSelectedItem().map((speaker, index) => (
                        <li key={index} onClick={() => setSelectedIndex(index)} className={selectedIndex === index ? 'speakerselected' : ''}>
                            <div className="speaker-list-name">
                                {speaker.name}
                            </div>
                            {selectedIndex === index && (
                                <>
                                    {isChair && (
                                        <div className="speaker-actions">
                                            <button
                                                type="button"
                                                className="call-speaker-button"
                                                title="Call Speaker"
                                                id="call speaker"
                                                onClick={() => callSpeaker(speaker)}
                                            >
                                                <i className="fa fa-podium" aria-hidden="true" />
                                            </button>
                                            <button
                                                className="delete-speaker-button"
                                                title="Delete Speaker"
                                                id="delete speaker"
                                                onClick={() => setIsDeleteConfirmationOpen(true)}
                                            >
                                                <i className="fa fa-trash" aria-hidden="true" />
                                            </button>

                                            {isDeleteConfirmationOpen && (
                                                <DeleteConfirmation
                                                    onClose={() => setIsDeleteConfirmationOpen(false)}
                                                    onDelete={() => deleteSpeaker(speaker)}
                                                />
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}