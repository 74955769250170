import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@trussworks/react-uswds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function PageNotFound() {
  let history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const renderReturnLink = (
    <Button type="button" unstyled onClick={goBack}>
      <FontAwesomeIcon
        icon={'angle-left'}
        size={'lg'}
        style={{ marginRight: 4 }}
      />
      <span>Go Back</span>
    </Button>
  );

  return (
    <>
      {history.length > 1 && renderReturnLink}
      <div id={'page-not-found-container'}>
        <h1>404</h1>
        <h2>Sorry, but that is not a valid link.</h2>
        <p>
          It may have been removed, had its name changed, or the address may be
          incorrect. If you need assistance, please contact{' '}
          <a
            href="https://granicus.com/support/"
            target="_blank"
            rel="noreferrer"
          >
            Customer Support
          </a>
          .
        </p>
      </div>
    </>
  );
}

PageNotFound.displayName = '404';
