import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { setEnableActiveAgendaErrorNotification } from '../ActiveAgenda/activeAgendaSlice'

const ErrorNotification = () => {

  const dispatch = useDispatch();
  const closeErrorNotification = () => {
    dispatch(setEnableActiveAgendaErrorNotification(false));
    //To reload the page after closing the errror notification
    window.location.reload();

  }

  return (
    <div id="error-container">
      <div id="time-stamp-field" className="error-container">
        <div id="time-stamp-info-panel">
          <div className="error-notification-header">
            <FontAwesomeIcon icon={['fas', 'info-circle']} size="lg" />
            <p>Unable to Join. The Meeting you have selected is no longer open to attendees.</p>
            <button id="close-error" aria-label="Close" className="close-button" onClick={closeErrorNotification}>
              <FontAwesomeIcon icon={['fas', 'times']} size="sm" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorNotification;