import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { useDispatch } from 'react-redux';
import { logout } from '../components/Auth/authSlice';

export const baseQueryWithoutReauth = fetchBaseQuery({
  baseUrl: '/',
  prepareHeaders(headers) {
    return headers;
  },
  credentials: 'include',
});

/**
 * This baseQuery will be use in Redux Api when you need to redirect to login if receives 401 response
 */
export const baseQuery = async(args, api, extraOptions) => {
  let result = await baseQueryWithoutReauth(args, api, extraOptions);
  if (
    result.error &&
    (result.error.status === 401 || result.error.originalStatus === 401)
  ) {
    api.dispatch(logout());
  }
  return result;
};
