import download from 'downloadjs';
import settings from '../common/settings';

/**
 * Download a file by url path, support GET request
 * @param {string} url - Url file path
 * @param {string} filename - The filename will be saved
 * @param {string} fileExt - The file extension is optional, will get type from server
 */
export const downloadFromUrl = async(url, filename, fileExt) => {
  // remove special char in filename
  const sanitizedFilename = filename.replace(/[^a-z0-9]/gi, '_');
  const response = await fetch(url, { method: 'GET' });
  const blob = await response.blob();
  if (response.status > 199 && response.status < 300) {
    if (fileExt) {
      download(blob, `${sanitizedFilename}.${fileExt}`);
    } else {
      download(blob, sanitizedFilename, blob.type);
    }
  } else {
    throw 'Error ' + response.status + ' ' + response.statusText;
  }
};

/**
 * Download a file by meta id, support GET request
 * @param {number} metaId - Url file path
 * @param {string} filename - The filename will be saved
 * This calls downloadFromUrl which throws errors if the download is unsuccessful
 */
export const downloadByMetaUid = async(metaId, filename, fileExt) => {
  const url = `${settings.metaDownloadUrl}${metaId}`;
  await downloadFromUrl(url, filename, fileExt);
};

export const getExtByFilename = (filename) => {
  if (!filename) {
    return '';
  }
  const index = filename.lastIndexOf('.');
  if (index >= 0) {
    return filename.substring(index + 1);
  }
};

export const getFileExtension = (urlString) => {
  urlString = urlString.toLowerCase();
  const validExts = ['jpeg', 'jpg', 'png', 'gif', 'svg', 'bmp', 'tif', 'tiff', 'ico', 'pdf', 'docx', 'doc', 'docm', 'dotx', 'dotm', 'xlsx',
    'xls', 'xlsm', 'xltx', 'xltm', 'xlsb', 'xlam', 'pptx', 'ppt', 'pptm', 'potx', 'potm', 'ppam', 'ppsx', 'ppsm', 'sldx', 'sldm', 'thmx',
    'rtf', 'txt', 'htm', 'html', 'webp', 'zip', 'sda', 'sdb', 'sdc', 'sdd'];
  //try the easy approach first, this should work with all non-legistar URLs and a few legistar ones
  let extension = getExtByFilename(urlString);
  if (validExts.includes(extension)){
    return extension;
  }
  else
  {
    let paramString = urlString.split('?')[1];
    let queryString = new URLSearchParams(paramString);
    //logicalfilename will be present in legistar 'view' urls
    let lfn = queryString.get('logicalfilename');
    let viewReport = urlString.includes('/viewreport.ashx?');
    //format specifier may be present in legistar 'viewreport' urls
    let format = queryString.get('format');
    if (lfn) {
      // if it's a legistar attachment the logical file name shows the original extension prior to UFC conversion.
      // In the use case of Legislate we always want to assume PDF, and show PDF extension on the UI
      return 'pdf';
    }
    if (viewReport && format) {
      return format;
    }
    else if (viewReport){
      //will be a pdf unless a format is specified
      return 'pdf';
    }
    //This is needed for attachments with Novus URLs
    if (urlString.includes('/attachmentviewer.ashx?')){
      return 'pdf';
    }
  }
  //if we can't determine the extension
  return '';
};

const fileTypeToIconDictinary = {
  DOC_TYPE: 'file-word',
  PDF_TYPE: 'file-pdf',
  EXCEL_TYPE: 'file-excel',
  IMAGE_TYPE: 'image',
  PPT_TYPE: 'file-powerpoint',
  DEFAULT: 'file',
};

const imageExts = ['jpeg', 'jpg', 'png', 'gif', 'svg', 'bmp', 'tif', 'tiff'];

export const getIconByFileExt = (ext) => {
  if (ext === 'pdf') {
    return fileTypeToIconDictinary.PDF_TYPE;
  } else if (ext === 'docx' || ext === 'doc') {
    return fileTypeToIconDictinary.DOC_TYPE;
  } else if (ext === 'xlsx' || ext === 'xls') {
    return fileTypeToIconDictinary.EXCEL_TYPE;
  } else if (imageExts.indexOf(ext) !== -1) {
    return fileTypeToIconDictinary.IMAGE_TYPE;
  } else if (ext === 'pptx' || ext === 'ppt') {
    return fileTypeToIconDictinary.PPT_TYPE;
  } else {
    return fileTypeToIconDictinary.DEFAULT;
  }
};

export const isPdf = (filename) => {
  const ext = getFileExtension(filename);
  return ext === 'pdf';
};
