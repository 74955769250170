import React, { useState } from 'react';
import { SideNav } from '@trussworks/react-uswds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { LegislateLogoSmallWhite } from '../../../../assets/images/legislate/imageIndex';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { toggleExpanded, selectExpanded } from './navigationSlice';
import { logout } from '../Auth/authSlice';
import LogoutModal from "./LogoutModal";

export default function Navigation() {
  const expanded = useSelector(selectExpanded);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const logOut = (e) => {
    e.preventDefault();
    setOpen(true);


  };

  const handleConfirmLogout = () =>{
    dispatch(logout());
  }

  const closeLogoutModal = () =>{
    setOpen(false);
  }

  const toggle = () => {
    dispatch(toggleExpanded());
  };

  /* eslint-disable react/jsx-key */
  const navLinks = [
    <NavItem
      toPath={'/agendas'}
      iconName={'list'}
      showLabel={expanded}
      label={'Agendas'}
    />,
    <NavItem
      toPath={'/notes'}
      iconName={'sticky-note'}
      showLabel={expanded}
      label={'Notes'}
    />,
    <NavItem
      toPath={'/bookmarks'}
      iconName={'bookmark'}
      showLabel={expanded}
      label={'Bookmarks'}
    />,
    <NavItem
      toPath={'/videos'}
      iconName={'play'}
      showLabel={expanded}
      label={'Videos'}
    />,
    <NavItem
      toPath={'/logout'}
      iconName={'sign-out-alt'}
      showLabel={expanded}
      label={'Sign Out'}
      onClickFn={logOut}
    />,
  ];
  /* eslint-enable react/jsx-key */
  return (
    <div id={'nav-container'}>
      <div className='nav-items-wrapper'>
        <div
          id={'nav-collapse-container'}
          expanded={expanded ? 'true' : 'false'}
        >
          <button
            onClick={toggle}
            aria-label={expanded ? 'Collapse' : 'Expand'}
            tabIndex='0'
          >
            <FontAwesomeIcon
              className='nav-collapse-arrow'
              icon={expanded ? 'angle-left' : 'angle-right'}
              size={'6x'}
            />
          </button>
        </div>
        <div id={'nav-items-container'}>
          <SideNav items={navLinks} />
        </div>
      </div>
      <div
        id={'nav-legislate-logo-container'}
        expanded={expanded ? 'true' : 'false'}
      >
        <div className='icon'>
          <img src={LegislateLogoSmallWhite} alt='' />
        </div>
        <div className='item-text'>Legislate</div>
      </div>
      <LogoutModal
        handleConfirm={handleConfirmLogout}
        handleCancel={closeLogoutModal}
        isOpen={open}/>
    </div>
  );
}

const NavItem = ({ toPath, iconName, label, showLabel, onClickFn }) => {
  return (
    <NavLink to={toPath} className={'nav-item'} onClick={onClickFn}>
      <div className='nav-select-bar'></div>
      <div className='nav-content' expanded={showLabel ? 'true' : 'false'}>
        <div className='icon'>
          <FontAwesomeIcon icon={iconName} />
        </div>
        <div className='item-text'>{label}</div>
      </div>
    </NavLink>
  );
};

Navigation.displayName = 'Navigation';
NavItem.displayName = 'NavItem';

NavItem.defaultProps = {
  onClickFn: null,
};

NavItem.propTypes = {
  toPath: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  showLabel: PropTypes.bool.isRequired,
  onClickFn: PropTypes.func,
};
