// Defined constants.
export const TEN_MINS_SEC = 600;
export  const FIVE_MINS_SEC = 300;

export const generateNewTimestamp = () => {
  return Math.floor(new Date().getTime() / 1000);
};

export const calculateEntrytime = (startTime, entryTime) => entryTime - startTime;

export const formattedTimeRemainingString = (timeRemainingSec, noTimeLeftString = '') => {
  if (timeRemainingSec > 0) {
    let hours = Math.floor(timeRemainingSec / 3600);
    let minutes = Math.floor((timeRemainingSec - (hours * 3600)) / 60);
    let seconds = timeRemainingSec - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
      hours = '0' + hours;
    }

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    if (seconds < 10) {
      seconds = '0' + seconds;
    }

    if (hours > 0) {
      return hours + ' hr ' + minutes + ' min ' + seconds + ' sec';
    } else if (minutes > 0) {
      return minutes + ' min ' + seconds + ' sec';
    } else {
      return seconds + ' sec';
    }
  } else {
    return noTimeLeftString;
  }
};

/**
 * Converts seconds to a time string in the format "HH:MM:SS".
 * @param {number} seconds - The number of seconds.
 * @returns {string} The time string in the format "HH:MM:SS".
 */
export const secondsToTimeString = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;
  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .join(":");
};
