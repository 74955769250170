import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@trussworks/react-uswds';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../../Common/Modal';
import AttachmentDownload from './AttachmentDownload';
import { isPdf } from '../../../helpers/fileHelper';
import AttachmentPdfViewer from './AttachmentPdfViewer';

const AttachmentModal = (props) => {
  const {
    isOpen,
    onNext,
    onPrevious,
    onClose,
    isFirst,
    isLast,
    attachment,
  } = props;

  if (!attachment) {
    return null;
  }

  const handleClose = () => {
    onClose();
  };

  const header = (
    <div className='attachment-modal-header display-flex flex-align-center'>
      <div className='flex-auto margin-right-2'>
        <Button
          type='button'
          className='item-nav-button'
          unstyled
          onClick={onPrevious}
          disabled={isFirst}
        >
          <FontAwesomeIcon
            icon={'angle-left'}
            size={'lg'}
            style={{ marginRight: 4 }}
          />
          <span>Previous attachment</span>
        </Button>
      </div>
      <div className='flex-auto'>
        <Button
          type='button'
          className='item-nav-button'
          unstyled
          onClick={onNext}
          disabled={isLast}
        >
          <span>Next attachment</span>
          <FontAwesomeIcon
            icon={'angle-right'}
            size={'lg'}
            style={{ marginLeft: 4 }}
          />
        </Button>
      </div>
    </div>
  );

  const getContent = () => {
    const isPdfAttachment = isPdf(attachment.url);
    return isPdfAttachment ? (
      <AttachmentPdfViewer attachment={attachment} />
    ) : (
      <AttachmentDownload attachment={attachment} />
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      closeTimeoutMS={300}
      headerContent={header}
      bodyContent={getContent()}
      contentLabel='Attachment'
      contentClassName='attachment-modal'
    ></Modal>
  );
};

AttachmentModal.displayName = 'AttachmentModal';

AttachmentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  attachment: PropTypes.object,
};

AttachmentModal.defaultProps = {
  isOpen: false,
  isFirst: false,
  isLast: false,
};

export default AttachmentModal;
