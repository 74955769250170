import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '@trussworks/react-uswds';
import AttachmentModal from './AttachmentModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIconByFileExt, getFileExtension } from '../../../helpers/fileHelper';

const AttachmentList = (props) => {
  const { attachments } = props;

  const [open, setOpen] = useState(false);
  const [selectedAttachmentIndex, setSelectedAttachmentIndex] = useState(
    undefined
  );

  const handleClickAttachment = (index) => {
    setSelectedAttachmentIndex(index);
    setOpen(true);
  };

  const handleCloseAttachmentModal = () => {
    setOpen(false);
  };

  const handleNextAttachmentClick = () => {
    if (selectedAttachmentIndex === attachments.length - 1) {
      return;
    }
    setSelectedAttachmentIndex(selectedAttachmentIndex + 1);
  };

  const handlePrevAttachmentClick = () => {
    if (selectedAttachmentIndex === 0) {
      return;
    }
    setSelectedAttachmentIndex(selectedAttachmentIndex - 1);
  };

  const attachmentContent = ({ title, fileType, index }) => {
    return (
      <React.Fragment key={index}>
        <div
          className='attachment-item display-flex'
          onClick={() => handleClickAttachment(index)}
        >
          <div>
            <span className='attachment-icon' style={{ marginRight: 10 }}>
              {getFileIcon(fileType)}
            </span>
          </div>
          <div className='attachment-title'>{title}</div>
          <div>&nbsp;{`${fileType ? '(.' + fileType + ')' : '(unknown)'}`}</div>
        </div>
      </React.Fragment>
    );
  };

  const accordionAttachment = () => {
    const accordionContent = attachments?.map((attachment, index) => {
      const fileType = getFileExtension(attachment.url);
      return attachmentContent({
        title: attachment?.name,
        fileType: fileType,
        index: index,
      });
    });

    const isEmpty = attachments?.length === 0;

    return [
      {
        title: `Attachments (${
          attachments?.length ? attachments?.length : '0'
        })`,
        content: accordionContent,
        expanded: true,
        id: 'attachments',
        headingLevel: 'h3',
        className: `${isEmpty ? 'no-attachment' : ''}`,
      },
    ];
  };

  return (
    <>
      <div className='text-black padding-0 margin-top-2 margin-bottom-2'>
        <Accordion
          items={accordionAttachment()}
          bordered={false}
          expanded={true}
          className='attachment-accordion'
        />
      </div>
      <AttachmentModal
        isOpen={open}
        onClose={handleCloseAttachmentModal}
        onNext={handleNextAttachmentClick}
        onPrevious={handlePrevAttachmentClick}
        isFirst={selectedAttachmentIndex === 0}
        isLast={selectedAttachmentIndex === attachments.length - 1}
        attachment={attachments[selectedAttachmentIndex]}
      />
    </>
  );
};

const getFileIcon = (fileType) => {
  if (!fileType){
    return (
      <span className="fa-layers fa-align-left" style={{ width: '.75em' }}>
        <FontAwesomeIcon icon={['fas', 'file']}/>
        <FontAwesomeIcon icon={['fas', 'question']} transform="shrink-8 down-2" color="white"/>
      </span>)
  } else {
    return (
      <FontAwesomeIcon
        icon={getIconByFileExt(fileType)}
        size='1x'
      />
    )
  }

};

AttachmentList.displayName = 'AttachmentList';

AttachmentList.propTypes = {
  attachments: PropTypes.array.isRequired,
};

export default AttachmentList;
