import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@trussworks/react-uswds";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedMonth as setSelectedAgendaMonth } from "../Agendas/agendaSlice";
import { setSelectedMonth as setSelectedVideoMonth } from "../Videos/videoSlice";
import { setSelectedMonth as setSelectedNoteMonth } from "../Notes/noteSlice";
import { setSelectedMonth as setSelectedBookmarkMonth } from "../Bookmarks/bookmarkSlice";
import { getPrevPageInfo } from '../../helpers/agendaHelper';

const Breadcrumb = () => {
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();

  const [from, prevPath] = getPrevPageInfo(location);

  const goBack = () => {
    switch (prevPath) {
      case '/agendas':
        dispatch(setSelectedAgendaMonth(location.state?.month));
        break;
      case '/videos':
        dispatch(setSelectedVideoMonth(location.state?.month));
        break;
      case '/notes':
        dispatch(setSelectedNoteMonth(location.state?.month));
        break;
      case '/bookmarks':
        dispatch(setSelectedBookmarkMonth(location.state?.month));
        break;
    }
    history.push(prevPath);
  };

  return (
    <Button type="button" unstyled onClick={() => goBack()}>
      <FontAwesomeIcon
        icon={"angle-left"}
        size={"lg"}
        style={{ marginRight: 4 }}
      />
      <span>Return to {from}</span>
    </Button>
  );
};

Breadcrumb.displayName = 'Breadcrumb';

export default Breadcrumb;
