import React from 'react';
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Button } from '@trussworks/react-uswds';

const LeaveMeetingButton = ({ onClick, disabled, isLoading, className }) => {

  return (
    <Button type="button" size='small' secondary
      disabled={disabled}
      className={classnames('btn-leave-meeting text-no-wrap', className)}
      onClick={(isLoading || disabled) ? undefined : onClick}>
      <FontAwesomeIcon icon={'times'} size={'lg'} className="btn-leave-meeting-icon margin-right-1" />
      <span>Leave Meeting</span>
    </Button>
  )
}

export default LeaveMeetingButton;

LeaveMeetingButton.displayName = 'Leave Meeting Button';

LeaveMeetingButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string
};
