import { createSlice } from "@reduxjs/toolkit";

export const SettingKeys = {
  CloudVotingEnable: "cloudvoting_enable",
  ShowIndividualVote: "cloudvoting_show_individual_vote",
};

const initialState = {
  CloudVotingEnable: false,
  ShowIndividualVote: false,
};

const settingsState = createSlice({
  name: "settingsState",
  initialState,
  reducers: {
    setSettingValue: (state, { payload }) => {
      for (var key in payload) {
        switch (key) {
          case SettingKeys.CloudVotingEnable:
            state.CloudVotingEnable = payload[key] === "1";
            break;

          case SettingKeys.ShowIndividualVote:
            state.ShowIndividualVote = payload[key] === "1";
            break;

          default:
            break;
        }
      }
    },
  },
});

export const { setSettingValue } = settingsState.actions;

export default settingsState.reducer;

export const selectSettings = (state) => state.settingsState;
export const selectIsCloudVotingEnabled = (state) =>
  state.settingsState.CloudVotingEnable;
export const selectIsShowIndividualVote = (state) =>
  state.settingsState.ShowIndividualVote;
