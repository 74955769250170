import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment } from 'react';
import {
  faBookmark as faBookmarkOutline,
  faComment,
  faStickyNote,
} from '@fortawesome/free-regular-svg-icons';

const itemTitle = (item, notes, bookmarks) => {
  const isBookmarked = bookmarks.indexOf(item.uid) !== -1;
  const hasNote = notes[item.uid]?.length > 0;
  const hasAttachment = item.attachments?.length > 0;
  const hasComment = item.comments?.length > 0;

  return (
    <Fragment>
      <div className="agenda-item-container">
        <span className="agenda-item-text-container">{item.name}</span>
        <span className="agenda-item-icon-container">
          {isBookmarked && (
            <FontAwesomeIcon
              className="agenda-item-icon"
              aria-label={`Bookmark icon for ${item.name}` }
              icon={faBookmarkOutline}
              size={'1x'}
              style={{ marginLeft: 4 }}
            />
          )}
          {hasNote && (
            <FontAwesomeIcon
              className="agenda-item-icon"
              aria-label={`Note icon for ${item.name}` }
              icon={faStickyNote}
              size={'1x'}
              style={{ marginLeft: 4 }}
            />
          )}
          {hasComment && (
            <FontAwesomeIcon
              className="agenda-item-icon"
              aria-label={`Comment icon for ${item.name}` }
              icon={faComment}
              size={'1x'}
              style={{ marginLeft: 4 }}
            />
          )}
          {hasAttachment && (
            <FontAwesomeIcon
              aria-label={`Attachment icon for ${item.name}` }
              icon={faPaperclip}
              size={'1x'}
              style={{ marginLeft: 4 }}
            />
          )}
        </span>
      </div>
    </Fragment>
  );
};

//return [treeData, itemsDict, keys]
export const buildAgendaData = (agenda, notes, bookmarks) => {
  let itemsDictionary = {};

  const itemRecursion = (items, itemsTree, keys) => {
    items.forEach((item) => {
      let newItem = {
        ...item,
        key: item.uid,
        title: itemTitle(item, notes, bookmarks),
        children: [],
      };

      keys.push(newItem.key);
      itemsDictionary[item.uid] = item;

      // recurse
      if (item.items?.length > 0) {
        itemRecursion(item.items, newItem.children, keys);
      }

      // save back the element.
      itemsTree.push(newItem);
    });
  };

  let keys = [];
  let itemsTree = [];
  itemRecursion(agenda?.items || [], itemsTree, keys);

  return [itemsTree, itemsDictionary, keys];
};

/**
 *
 * @param {LocationDescriptorObject} location passed from useLocation()
 * @returns {[string, string]} [from, prevPath] Prev page name and Prev path
 *
 */
export const getPrevPageInfo = (location) => {
  const prevPath =
    location?.state?.prevPath ?? getRootPagePathFromUrl(location.pathname);

  let firstLetter = prevPath.match(/\w/)[0];
  const from = prevPath
    .replace(/\//g, '')
    .replace(firstLetter, firstLetter.toUpperCase());

  return [from, prevPath];
};

export const validatePacketUrl = (agenda) => {
  let urlString
  if (agenda.council_packet_url) {
    urlString = agenda.council_packet_url
  } else if (agenda.agenda_packet_url) {
    urlString = agenda.agenda_packet_url
  } else if (agenda.pre_published_agenda_packet_url) {
    urlString = agenda.pre_published_agenda_packet_url
  } else {
    urlString = null
  }

  return isValidUrl(urlString) ? urlString : null
};

const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  }
  catch (e){
    return false;
  }
}

const getRootPagePathFromUrl = (url) => {
  if (!url) {
    return '/';
  }
  const match = url.match(/^\/?\w+/);
  if (!match || match.length === 0) {
    return '/';
  }
  let path = match[0];
  if (path.charAt(0) !== '/') {
    path = '/' + path;
  }
  return path;
};
