export const onAgendaItemRecord = /* GraphQL */ `
  subscription onVotecastAgendaItemRecord($customer_uid: String!, $agenda_uid: String!, $status: String!) {
    onVotecastAgendaItemRecord(customer_uid: $customer_uid, agenda_uid: $agenda_uid, status: $status) {
        customer_uid
        uid
        agenda_uid
        status
        title
        type
        mema_metadata_id
    }
  }
`;

export const onMotionUpdate = `
  subscription onVotecastMotionUpdate($customer_uid: String!, $agenda_uid: String!) {
    onVotecastMotionUpdate(customer_uid: $customer_uid, agenda_uid: $agenda_uid) {
        uid
        customer_uid
        agenda_uid
        position
        entrytime
        request_origin
        motion_action_name
        motion_result_name
        motioner_uid
        motioner_name
        seconder_uid
        seconder_name
    }
  }
`;

export const onCreateUpdateAgendaItemForVote = `
  subscription onVotecastCreateUpdateAgendaItemForVote($customer_uid: String!, $agenda_uid: String!, $type: String!) {
    onVotecastCreateUpdateAgendaItemForVote(customer_uid: $customer_uid, agenda_uid: $agenda_uid, type: $type) {
      customer_uid
      uid
      agenda_uid
      bulk_action
      title
      type
      entrytime
      mema_metadata_id
      metadata
    }
  }
`;

export const onBallotUpdate = `
  subscription onVotecastUpdateBallot($customer_uid: String!, $agenda_uid: String!) {
    onVotecastUpdateBallot(customer_uid: $customer_uid, agenda_uid: $agenda_uid) {
      uid
      customer_uid
      agenda_uid
      motion_uid
      parent_agenda_item_uid
      member_uid
      member_name
      voting_configuration_name
      request_origin
    }
  }
`;

export const onVotecastDeleteAgendaItem = `
  subscription onVotecastDeleteAgendaItem($agenda_uid: String!) {
    onVotecastDeleteAgendaItem(agenda_uid: $agenda_uid) {
      agenda_uid
      uid
      type
      metadata
    }
  }
`;

export const onMeetingEndUpdate = `
  subscription onVotecastStopMeeting($uid: String!) {
    onVotecastStopMeeting(uid: $uid) {
        uid
    } 
  }
`;

export const onVotecastMemberUpdate = `
  subscription onVotecastMemberUpdate($uid: ID!, $customer_uid: String!, $agenda_uid: String!) {
    onVotecastMemberUpdate(uid: $uid, customer_uid: $customer_uid, agenda_uid: $agenda_uid){
      uid
      customer_uid
      agenda_uid
      display_name
      status
      availability_status
      voting
      chair
      person_uid
    }
  }
`;

export const onVotecastCreateDeleteSpeaker = `
  subscription onVotecastCreateDeleteSpeaker($customer_uid: String!, $agenda_uid: String!) {
    onVotecastCreateDeleteSpeaker(customer_uid: $customer_uid, agenda_uid: $agenda_uid) {
      customer_uid
      agenda_uid
      uid
      item_uid
      duration
      name
      position
      specialPosition
      speaker_type
      start_time
      status
      request_origin
    }
  }
`;

export const onVotecastDeleteAttendee = `
  subscription onVotecastDeleteMember($uid: ID!, $customer_uid: String!) {
    onVotecastDeleteMember(uid: $uid, customer_uid: $customer_uid) {
      uid
      customer_uid
    }
  }
`;

export const OnUpdateVotecastAgendaMeetingStatus = `
  subscription OnUpdateVotecastAgendaMeetingStatus($uid: ID!, $customer_uid: String!) {
    onUpdateVotecastAgendaMeetingStatus(uid: $uid, customer_uid: $customer_uid) {
      uid
      customer_uid
      meeting_status
      activeclip_uid
    }
  }
`;