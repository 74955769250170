import React from 'react';

const DeleteConfirmation = ({ onClose, onDelete }) => {
  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Delete Speaker?</h2>
        <p>The action cannot be undone.</p>
        <div className="modal-actions">
          <div>
            <button className="cancel-button" onClick={onClose}>Cancel</button>
          </div>
          <div>
            <button className= "delete-button" onClick={onDelete}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteConfirmation;