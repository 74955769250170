import React from 'react';
import PropTypes from 'prop-types';
import Comment from './Comment';

const CommentModalBody = (props) => {
  const { comments, displaySentiment, expanded } = props;

  const renderComments = () => {
    return comments.map((comment) => {
      return (
        <Comment
          key={comment.user.id}
          comment={comment}
          displaySentiment={displaySentiment}
          defaultExpanded={expanded}
        />
      );
    });
  };

  const render = () => {
    return (
      <div className={'comment-modal-body'} aria-label={'Comment modal body'}>
        <ul
          className={'comment-list'}
          aria-label={'List of comments'}
          data-testid={'comment-modal-list'}
        >
          {renderComments()}
        </ul>
      </div>
    );
  };

  return <>{render()}</>;
};

CommentModalBody.displayName = 'CommentModalBody';

CommentModalBody.propTypes = {
  comments: PropTypes.array.isRequired,
  displaySentiment: PropTypes.bool.isRequired,
  expanded: PropTypes.bool,
};

CommentModalBody.defaultProps = {
  expanded: false,
};

export default CommentModalBody;
