import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { selectSelectedItem, selectSelectedItemKey } from "../activeAgendaSlice";
import { Button } from "@trussworks/react-uswds";

const CommentModalHeader = (props) => {
  const {
    comments,
    displaySentiment,
    sentimentPercentages,
    onExpand
  } = props;

  const selectedItemKey = useSelector(selectSelectedItemKey);

  const item = useSelector((state) =>
    selectSelectedItem(state, selectedItemKey)
  );

  const handleExpand = (expand) => {
    onExpand(expand)
  }

  const renderSentiment = () => {
    return (
      <div className={'comment-modal-sentiments-container'} aria-label={'Comment modal total sentiments'}>
        <div className={'comment-modal-sentiment support-text'}>
          <span
            className={'comment-modal-percentage support-percentage'}
            aria-label={'Support percentage'}
            data-testid={'support-percentage'}
          >
            { sentimentPercentages.support }%
          </span>
          <span>Support</span>
        </div>
        <div className={'comment-modal-sentiment oppose-text'}>
          <span
            className={'comment-modal-percentage oppose-percentage'}
            aria-label={'Oppose percentage'}
            data-testid={'oppose-percentage'}
          >
            { sentimentPercentages.oppose }%
          </span>
          <span>Oppose</span>
        </div>
        <div className={'comment-modal-sentiment neutral-text'}>
          <span
            className={'comment-modal-percentage neutral-percentage'}
            aria-label={'Neutral percentage'}
            data-testid={'neutral-percentage'}
          >
            { sentimentPercentages.neutral }%
          </span>
          <span>Neutral</span>
        </div>
        <div className={'comment-modal-sentiment no-response-text'}>
          <span
            className={'comment-modal-percentage no-response-percentage'}
            aria-label={'No response percentage'}
            data-testid={'no-sentiment-percentage'}
          >
            { sentimentPercentages.noResponse }%
          </span>
          <span>No Sentiment</span>
        </div>
      </div>
    )
  }

  const renderItemMetadata = () => {
    return (
      <div className={'comment-modal-body-item-metadata-container'}>
        <span className={'comment-modal-body-item-name'} data-testid={'comment-modal-item-name'}>
          { item.name }
        </span>
        { displaySentiment && renderSentiment()}
      </div>
    )
  }

  const renderListControls = () => {
    return (
      <div className={'comment-list-header'} aria-label={'Comment List Header'}>
        <div aria-label={'Comment list expand or collapse buttons'}>
          <Button type="button" unstyled value={'Expand All'} onClick={() => handleExpand(true)}>
            Expand All
          </Button>
          <span className={'comment-secondary-text'}> | </span>
          <Button type="button" unstyled onClick={() => handleExpand(false)}>
            Collapse All
          </Button>
        </div>
        <div aria-label={'Comment list count'}>
          <span className={'comment-secondary-text'} data-testid={'comment-modal-count'}> {comments.length} Comment{(comments.length === 1 ? "" : "s")}</span>
        </div>
      </div>
    )
  }

  return (
    <div className={'comment-modal-header'}>
      <span className={'comment-modal-header-title'}> eComment </span>
      { renderItemMetadata() }
      { renderListControls() }
    </div>
  );
};

CommentModalHeader.displayName = 'CommentModalHeader';

CommentModalHeader.propTypes = {
  // eslint-disable-next-line react/require-default-props
  comments: PropTypes.array.isRequired,
  displaySentiment: PropTypes.bool.isRequired,
  sentimentPercentages: PropTypes.object.isRequired,
  onExpand: PropTypes.func
};

export default CommentModalHeader;
