import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: 'auth/sessions',
        method: 'POST',
        body: credentials
      }),
    }),
    resetPassword: builder.mutation({
      query: (username) => ({
        url: 'auth/forgot',
        method: 'POST',
        body: { username , service:'legislate' }
      })
    }),
    getCurrentUser: builder.query({
      query: () => `legislate/api/user`,
    }),
    // getCurrentPersonUid: builder.query({
    //   query: (username) =>  ({
    //     url: 'services/minutes/users/get_users_details',
    //     method: 'POST',
    //     body: { usernames : [username] }
    //   })
    // }),
  })
});
export const { useLoginMutation, useResetPasswordMutation, useGetCurrentUserQuery } = authApi;
// export const { useLoginMutation, useResetPasswordMutation, useGetCurrentUserQuery, useGetCurrentPersonUidQuery } = authApi;
