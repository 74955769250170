import { Alert, Button } from '@trussworks/react-uswds';
import React from 'react';

const FallbackComponent = () => {
  return (
    <div className="fallback">
      <Alert type="error" heading="An error has occurred">
        An error has occurred and has been reported to Granicus support.
        <br />
        Please{' '}
        <Button type="button" unstyled onClick={() => window.location.reload()}>
          click here to reload
        </Button>{' '}
        page.
        <br />
        If issue persists, contact{' '}
        <a href="https://granicus.com/support/" target="blank">
          Granicus support
        </a>
        .
      </Alert>
    </div>
  );
};

FallbackComponent.displayName = 'FallbackComponent';

export default FallbackComponent;
